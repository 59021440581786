
import { mapGetters } from 'vuex';

export default {
  name: 'AppAffiliateLogo',
  computed: {
    ...mapGetters('affiliate', ['branding']),
    logo() {
      return this.branding.logo;
    },
  },
};
