
import { debounce } from 'throttle-debounce';

export default {
  name: 'AppTabs',
  props: {
    tabs: {
      required: true,
      type: Array,
    },
    value: {
      default: null,
      type: Number,
    },
    variant: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      selectedTab: 0,
      transitionDirection: 'right',
      childrenWidth: -1,
      parentWidth: 0,
      scrollLeft: 0,
    };
  },
  computed: {
    overFlowClass() {
      return { '--overflow': this.childrenWidth >= this.parentWidth };
    },
    showLeft() {
      return this.scrollLeft > 0;
    },
  },
  watch: {
    value(value) {
      this.selectTab(value, true);
    },
  },
  mounted() {
    if (this.value > 0) {
      this.selectedTab = this.value;
    }
    this.measureAndAdjust();
    window.addEventListener('resize', this.measureAndAdjust);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.measureAndAdjust);
  },
  methods: {
    isSelected(tabIndex) {
      return this.selectedTab === tabIndex;
    },
    selectTab(tabIndex, externalInput) {
      if (this.selectedTab > tabIndex) {
        this.transitionDirection = 'right';
      } else {
        this.transitionDirection = 'left';
      }
      this.selectedTab = tabIndex;
      if (!externalInput) this.$emit('tabSelected', tabIndex);
    },
    measureAndAdjust: debounce(100, function () {
      this.childrenWidth =
        this.$refs.tabs.lastChild.getBoundingClientRect().right;
      this.parentWidth = this.$refs.tabs.offsetWidth;
    }),
    scrollToRight() {
      this.$refs.tabs.scrollBy({
        top: 0,
        left: +100,
        behavior: 'smooth',
      });
    },
    scrollToLeft() {
      this.$refs.tabs.scrollBy({
        top: 0,
        left: -100,
        behavior: 'smooth',
      });
    },
    onScroll: debounce(100, function () {
      this.scrollLeft = this.$refs.tabs.scrollLeft;
    }),
  },
};
