
import anime from 'animejs';

export default {
  name: 'AppBaseButton',
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    focusable: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    to: {
      default: null,
      type: String,
    },
    type: {
      default: 'button',
      type: String,
    },
  },
  data() {
    return {
      width: 0,
    };
  },
  computed: {
    classes() {
      return {
        'btn-disabled': this.disabled && !this.loading,
        'btn-focus': this.focusable,
        'cursor-wait': this.loading,
      };
    },
    styles() {
      return {
        width: this.loading ? `${this.width}px` : null,
      };
    },
  },
  watch: {
    loading(isLoading) {
      this.width = isLoading ? this.$el.getBoundingClientRect().width : 0;
    },
  },
  methods: {
    click() {
      if (!this.to && !this.loading) {
        this.$emit('click');
      }
    },
    enter(el, complete) {
      anime.remove(el);

      anime({
        targets: el,
        opacity: 1,
        duration: 150,
        easing: 'easeInOutQuad',
        complete,
      });
    },
    leave(el, complete) {
      anime.remove(el);

      anime({
        targets: el,
        opacity: 0,
        duration: 150,
        easing: 'easeInOutQuad',
        complete,
      });
    },
  },
};
