
import { saveAs } from 'file-saver';
import GET_BULK_COUPONS_QUERY from '@/graphql/queries/GetBulkCoupons';

export default {
  name: 'AppBulkDownloadCouponModal',
  data: () => ({
    loading: false,
    checkboxProductsMap: {
      WILL: false,
      POA: false,
      PARTNER_WILL: false,
    },
    formData: {
      partnershipSlug: null,
      createdAtMinTime: null,
      createdAtMaxTime: null,
      description: null,
      countryCode: null,
      singleUse: null,
      active: true,
    },
  }),
  methods: {
    close() {
      this.loading = false;
      this.$emit('closeModal');
    },
    async onSubmit() {
      this.loading = true;
      try {
        const {
          data: { getBulkCoupons },
        } = await this.$apollo.query({
          query: GET_BULK_COUPONS_QUERY,
          variables: {
            ...this.formData,
          },
        });
        const blob = new Blob(
          ['Code\n', ...getBulkCoupons.map((code) => `${code}\n`)],
          {
            type: 'text/plain;charset=utf-8',
          }
        );
        saveAs(
          blob,
          `${this.formData.partnershipSlug}-codes-${this.formData.createdAtMinTime}-to-${this.formData.createdAtMaxTime}.csv`
        );
        this.close();
      } catch (err) {
        console.error(err);
        this.$emit('failedDownload');
        this.close();
      }
    },
  },
};
