
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';

import confirm from '@/mixins/confirm';
import { partnerships } from '@/mixins/apollo';
import { filterNullish } from '@/utilities';

export default {
  name: 'PagesAffiliateAdminPartnershipsIndex',
  mixins: [confirm, partnerships],
  layout: 'admin',
  middleware: ['adminAuthenticated'],
  apollo: {
    partnerships: {
      query: GET_PARTNERSHIPS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          ...(this.searchQuery ? { searchQuery: this.searchQuery } : {}),
          filters: filterNullish(this.filters),
          limit: this.pageSize,
          offset: this.pageIndex * this.pageSize,
          customOrder: this.sortOrder,
        };
      },
      update: (data) => data?.getPartnerships,
    },
  },
  data() {
    return {
      columns: [
        'Name',
        '# Active Coupons',
        'Created At',
        'Updated At',
        'Active',
      ],
      statusFilters: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Active',
          value: true,
        },
        {
          label: 'Inactive',
          value: false,
        },
      ],
      searchInput: null,
    };
  },
  computed: {
    ...mapGetters('admin/partnership-table', [
      'pageIndex',
      'pageSize',
      'searchQuery',
      'filters',
      'sort',
      'sortOrder',
      'sortableColumns',
    ]),
    partnershipTypeOptions() {
      return [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'Affiliate',
          value: 'affiliate',
        },
        {
          text: 'Charity / Not For Profit',
          value: 'charity',
        },
      ];
    },
    partnershipType: {
      get() {
        if (this.filters.affiliateEnabled) {
          return 'affiliate';
        } else if (this.filters.bequestEnabled) {
          return 'charity';
        }
        return null;
      },
      set(value) {
        this.updateFilters({
          affiliateEnabled: value === 'affiliate' ? true : null,
          bequestEnabled: value === 'charity' ? true : null,
        });
      },
    },
  },
  mounted() {
    this.searchInput = this.searchQuery;
  },
  methods: {
    ...mapActions('admin/partnership', [
      'setShowNewPartnershipModal',
      'downloadCsv',
    ]),
    ...mapActions('admin/partnership-table', [
      'nextPage',
      'prevPage',
      'setFilters',
      'setSearchQuery',
      'setSort',
    ]),
    reloadPartnerships() {
      this.$apollo.queries.partnerships.refetch();
    },
    setPartnershipStatus(partnership) {
      const missingFields = this.getPartnershipMissingFields(partnership);

      if (missingFields.length) {
        return this.$nuxt.$emit('snackbar', {
          placement: 'top-right',
          type: 'error',
          text:
            'The following field(s) are missing: ' + missingFields.join(', '),
        });
      }

      const title = `${!partnership.active ? 'Activate' : 'Deactivate'} ${
        partnership.name
      }?`;
      const activationText = `Are you sure you want to activate ${partnership.name}?
       Activating this partnership will display the partnership on the platform and activate their dashboard.`;

      const deactivationText = `Are you sure you want to deactivate ${partnership.name}?
       Deactivating this partnership will remove the partnership from the platform and deactivate their dashboard.`;

      this.$confirm(
        {
          title,
          message: !partnership.active ? activationText : deactivationText,
        },
        async (action) => {
          if (action) {
            await this.updatePartnershipPartialData(partnership.id, {
              active: !partnership.active,
            });
          }
        }
      );
    },
    search: debounce(300, function (event) {
      this.setSearchQuery(event.target.value.trim());
    }),
    updateFilters(value) {
      this.setFilters({
        ...this.filters,
        ...value,
      });
    },
    toggleFeaturedFiveFilter() {
      this.updateFilters({
        featuredFive: this.filters.featuredFive ? null : true,
      });
    },
  },
};
