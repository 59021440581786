import { render, staticRenderFns } from "./verify.vue?vue&type=template&id=20d2a724"
import script from "./verify.vue?vue&type=script&lang=js"
export * from "./verify.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppForm: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Form.vue').default,AppLoadingDots: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/LoadingDots.vue').default,AppAuthPanel: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/AuthPanel.vue').default})
