
import { mapGetters } from 'vuex';

export default {
  name: 'AppSnowflakesNotices',
  data() {
    return {
      showMaintenanceAlert: false,
    };
  },
  computed: {
    ...mapGetters(['masquerading']),
    showNotices() {
      return (
        this.masquerading || this.$nuxt.isOffline || this.showMaintenanceAlert
      );
    },
  },
};
