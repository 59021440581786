
import { mapGetters, mapActions } from 'vuex';
import { user } from '@/mixins/apollo';
import { formatError } from '@/utilities';
import CREATE_INVITE_MUTATION from '@/graphql/mutations/CreateInvite';

export default {
  name: 'AppAccountSendInvite',
  mixins: [user],
  props: {
    type: {
      default: 'DEFAULT',
      type: String,
    },
    isIncomplete: {
      default: false,
      type: Boolean,
    },
    products: {
      type: Array,
      default: () => ['WILL'],
    },
    prefill: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      email: this.prefill,
      isBusy: false,
      showAlert: null,
      alertTheme: null,
      alertMessage: null,
      isInvalid: null,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('invites', ['invites']),
  },
  watch: {
    prefill() {
      this.email = this.prefill;
    },
  },
  methods: {
    ...mapActions('directory-person', ['getDirectoryPersonsByOwnerId']),
    ...mapActions('invites', ['getInvites']),
    async sendInvite() {
      this.isBusy = true;
      this.alertMessage = null;
      try {
        await this.$apollo.mutate({
          mutation: CREATE_INVITE_MUTATION,
          variables: {
            userId: this.userId,
            type: this.type,
            email: this.email,
            products: this.products,
          },
          update: () => {
            this.getDirectoryPersonsByOwnerId({ ownerId: this.userId });
            this.getInvites();
          },
        });

        this.$nuxt.$emit('sendTrackingAttributes', {
          partner_invite_created_frontend: this.invites.some(
            (i) => i.type === 'PARTNER'
          ),
          friend_invite_created_frontend: this.invites.some(
            (i) => i.type === 'DEFAULT'
          ),
          number_of_invitations: this.invites.filter((i) =>
            ['PARTNER', 'DEFAULT'].includes(i.type)
          ).length,
        });

        this.$nuxt.$emit('snackbar', {
          icon: 'success',
          type: 'success',
          text: this.$t('components.sendInvite.successfullySentInvite'),
        });
        this.$refs.formControl.$children[0].reset();
        this.$refs.formControl.reset();
        this.$emit('complete');
      } catch (error) {
        this.alertTheme = 'error';
        this.alertMessage = this.$t(formatError(error.message));
        this.$emit('error', this.alertMessage);
      }
      this.isBusy = false;
    },
  },
};
