
import { mapGetters, mapActions } from 'vuex';
import startCase from 'lodash/startCase';
import {
  formatError,
  getSubordinateRoleOptionsByRole,
  getSubordinateRolesByRole,
} from '@/utilities';

import confirm from '@/mixins/confirm';

import GET_USER_QUERY from '@/graphql/queries/GetUser';
import UPDATE_USER_ROLE_MUTATION from '@/graphql/mutations/UpdateUserRole';
import GET_WILLS_QUERY from '@/graphql/queries/GetWills';
import GET_WILL_BY_EMAIL from '@/graphql/queries/GetWillByEmail';
import DELETE_USER from '@/graphql/mutations/DeleteUser';
import UPDATE_DIRECTORY_COUNTRY_MUTATION from '@/graphql/mutations/UpdateDirectoryCountry';
import GET_CART_ITEMS_BY_USER_ID_QUERY from '@/graphql/queries/GetCartItemsByUserId';
import UPDATE_USER_ADMIN from '@/graphql/mutations/UpdateUserAdmin';

import adminUserAction from '@/mixins/admin-user-action';
import { subscription } from '@/mixins/apollo';
import orders from '@/mixins/apollo/orders';

export default {
  name: 'PagesAffiliateAdminUsersId',
  mixins: [confirm, adminUserAction, orders, subscription],
  layout: 'admin',
  apollo: {
    will: {
      query: GET_WILL_BY_EMAIL,
      fetchPolicy: 'network-only',
      variables() {
        return {
          email: this.user?.email,
        };
      },
      skip() {
        return !this.isUserConsumer || !this.user?.email;
      },
      update: (data) => {
        if (data.getWillByEmail) {
          return data.getWillByEmail.will;
        }
        return null;
      },
    },
    historicalWills: {
      query: GET_WILLS_QUERY,
      update: (data) => {
        return data.getWills;
      },
      fetchPolicy: 'network-only',
      variables() {
        return {
          filters: { userId: this.userId },
        };
      },
      skip() {
        return !this.isUserConsumer || !this.userId;
      },
    },
    cart: {
      query: GET_CART_ITEMS_BY_USER_ID_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          userId: this.user?.id,
        };
      },
      skip() {
        return !this.user?.person_id;
      },
      update: (data) => data.getCartItemsByUserId,
    },
  },
  data() {
    return {
      userRole: '',
      userCountry: '',
      historicalWills: [],
      will: null,
      userNewEmail: '',
      user: null,
      person: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['role']),
    userId() {
      return this.$route.params.id;
    },
    contactRows() {
      return [
        ['Name', this.person?.fullName],
        ['Alternative Name', this.person?.altFullName],
        ['ID', this.user?.id],
        ['Email', this.user?.email],
        ['Role', startCase(this.user?.role)],
        ['Verified', this.user?.verified],
        ['Country', this.person?.residentialAddress?.country],
      ];
    },
    subscriptionRows() {
      return [
        ['ID', this.subscription.id],
        ['Stripe Subscription id', this.subscription.stripeSubscriptionId],
        ['Auto renew', this.subscription.autoRenew],
        [
          'Payment method expires at',
          this.formattedSubscriptionPaymentMethodExpiryDate,
        ],
        ['Expires at', this.formattedSubscriptionExpiryDate],
        ['Created at', this.formattedSubscriptionCreatedAtDate],
      ];
    },
    isUserSuperAdmin() {
      return this.role === 'SUPER_ADMIN';
    },
    isUserConsumer() {
      return this.user?.role === 'CONSUMER';
    },
    showChangeUserRoleCard() {
      const subordinates = getSubordinateRolesByRole(this.role);
      return subordinates.length > 1 && subordinates.includes(this.user?.role);
    },
  },
  async mounted() {
    await this.getUserData();
  },
  methods: {
    getSubordinateRoleOptionsByRole,
    ...mapActions('admin', ['setUsersPageSearch', 'masquerade']),
    ...mapActions('directory-person', ['getDirectoryPersonData']),
    changeUserRole({ target: { value: userNewRole } }) {
      const userRoleString = startCase(userNewRole);
      this.$confirm(
        {
          title: `Change user role to ${userRoleString}?`,
          message: `Are you sure you want to assign ${userRoleString} role to ${this.user?.email}?`,
        },
        async (action) => {
          if (action) {
            try {
              await this.$apollo.mutate({
                mutation: UPDATE_USER_ROLE_MUTATION,
                variables: {
                  id: this.user.id,
                  role: userNewRole,
                },
              });
            } catch (error) {
              this.$nuxt.$emit('snackbar', {
                placement: 'top-right',
                type: 'error',
                text: formatError(error.message),
              });
            }
          } else {
            this.userRole = this.user?.role;
          }
        }
      );
    },
    deleteUser() {
      this.$confirm(
        {
          title: `Delete user ${this.user?.email}?`,
          message: `All user information will be permanently deleted.`,
        },
        async (action) => {
          if (action) {
            try {
              await this.$apollo.mutate({
                mutation: DELETE_USER,
                variables: {
                  userId: this.user.id,
                },
              });
              this.setUsersPageSearch(null);
              this.$router.push({ path: this.localePath('/admin/users') });
            } catch (error) {
              this.$nuxt.$emit('snackbar', {
                placement: 'top-right',
                type: 'error',
                text: formatError(error.message),
              });
            }
          }
        }
      );
    },
    changeCountry({ target: { value: userNewCountry } }) {
      if (userNewCountry === this.person.residentialAddress.country) {
        return;
      }
      this.$confirm(
        {
          title: `Change the country to ${userNewCountry}?`,
          message: `Note: Changing from ${this.person.residentialAddress.country} to ${userNewCountry} will wipe sections of the current address.`,
        },
        async (action) => {
          if (action) {
            await this.$apollo.mutate({
              mutation: UPDATE_DIRECTORY_COUNTRY_MUTATION,
              variables: {
                id: this.person?.id,
                country: userNewCountry,
              },
            });
          } else {
            this.userCountry = this.person.residentialAddress.country;
          }
        }
      );
    },
    changeEmail() {
      this.$confirm(
        {
          title: `Change the email to ${this.userNewEmail}?`,
          message: `Note: this email must be unique.`,
        },
        async (action) => {
          if (action) {
            try {
              await this.$apollo.mutate({
                mutation: UPDATE_USER_ADMIN,
                variables: {
                  id: this.userId,
                  userData: {
                    email: this.userNewEmail,
                  },
                },
              });
              this.user.email = this.userNewEmail;
              this.userNewEmail = '';
              this.$nuxt.$emit('snackbar', {
                placement: 'top-right',
                type: 'success',
                text: `Changed users email`,
              });
            } catch (error) {
              this.$nuxt.$emit('snackbar', {
                placement: 'top-right',
                type: 'error',
                text: formatError(error.message),
              });
            }
          }
        }
      );
    },
    async getUserData() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.query({
          query: GET_USER_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            id: this.userId,
          },
        });
        const user = data.getUser;
        this.user = user;
        this.userRole = user.role;

        if (user?.person_id && !this.person) {
          const person = await this.getDirectoryPersonData(user?.person_id);
          this.person = person;
          this.userCountry = person.residentialAddress.country;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
