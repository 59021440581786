
import { mapGetters, mapActions } from 'vuex';
import { formatPrice } from '@/utilities';
import { WILL_STATUS } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateOnboardingIntrodution',
  layout: 'ruach',
  data() {
    return {
      isFeaturesOpen: false,
    };
  },
  computed: {
    ...mapGetters('affiliate', ['slug']),
    ...mapGetters('product-prices', ['willPrice', 'willTierTwoPrice']),
    ...mapGetters('directory-person', ['userDetails']),
  },
  mounted() {
    this.getProductPrices();
  },
  methods: {
    ...mapActions('cart', ['addToCart']),
    ...mapActions('will', ['updateWillStatus']),
    ...mapActions('product-prices', ['getProductPrices']),
    formatPrice,
    async startWill() {
      this.sendSignUpEvent();
      await this.addToCart({
        codes: ['WILL'],
        showSnackbar: false,
      });
      await this.updateWillStatus(WILL_STATUS.IN_PROGRESS);
      this.$router.push({ path: this.localePath('/will/about-yourself/name') });
    },
    toggleFeatures() {
      this.isFeaturesOpen = !this.isFeaturesOpen;
    },
    sendSignUpEvent() {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'sign_up',
        props: {
          userId: this.userId,
        },
      });
    },
    skipWill() {
      this.sendSignUpEvent();
      const slug = `/${this.slug}`;
      this.$router.push({ path: this.localePath(slug) });
    },
  },
};
