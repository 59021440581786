
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships.gql';
import confirm from '@/mixins/confirm';
import { coupon } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateAdminCouponsId',
  mixins: [coupon, confirm],
  layout: 'admin',
  apollo: {
    partnerships: {
      query: GET_PARTNERSHIPS_QUERY,
      fetchPolicy: 'network-only',
      update: (data) => data?.getPartnerships,
    },
  },
  data() {
    return {
      loading: false,
      products: {
        WILL: false,
        POA: false,
        PARTNER_WILL: false,
      },
    };
  },
  computed: {
    partnershipsOptions() {
      return (this.partnerships || []).map((partnership) => {
        return {
          text: partnership.name,
          value: partnership.id,
        };
      });
    },
    couponId() {
      return this.$route.params.id;
    },
    couponRows() {
      return [
        ['ID', this.coupon?.id],
        ['Code', this.coupon?.code],
        ['Factor', this.coupon?.factor],
        ['Type', this.coupon?.type],
        ['Single Use', this.coupon?.singleUse],
      ];
    },
  },
  watch: {
    coupon(coupon) {
      coupon.products.forEach((product) => (this.products[product] = true));
    },
  },
  methods: {
    setCouponActive(coupon) {
      this.$confirm(
        {
          title: `${
            coupon.active ? 'Activate' : 'Deactivate'
          } ${coupon.code.toUpperCase()}?`,
          message: `Are you sure you want to ${
            coupon.active ? 'activate' : 'deactivate'
          } this coupon?`,
        },
        (action) => {
          if (action) {
            this.updateCoupon(coupon);
          } else {
            coupon.active = !coupon.active;
          }
        }
      );
    },
    showArchiveCouponModal() {
      this.$confirm(
        {
          title: `Archive ${this.coupon.code.toUpperCase()}?`,
          message: 'Are you sure you want to archive this coupon?',
        },
        (action) => {
          if (action) {
            this.archiveCoupon(this.coupon);
          }
        }
      );
    },
    updateProductSelection(key, value) {
      this.products[key] = value;
      const productEntries = Object.entries(this.products);
      this.coupon.products = productEntries
        .filter((el) => el[1])
        .map((el) => el[0]);
    },
    async updateCouponData() {
      this.loading = true;
      await this.updateCoupon(this.coupon);
      this.loading = false;
    },
  },
};
