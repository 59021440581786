
import { ValidationObserver } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'PagesAffiliateOnboardingUserGetStarted',
  components: {
    ValidationObserver,
  },
  layout: 'ruach',
  computed: {
    ...mapGetters('user-onboarding', ['currentStepData', 'loading']),
    ...mapFields('user-onboarding', [
      'payload.firstName',
      'payload.middleName',
      'payload.lastName',
    ]),
  },
  methods: {
    ...mapActions('user-onboarding', ['saveName']),
  },
};
