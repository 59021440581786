
import anime from 'animejs';

export default {
  name: 'AppDataTable',
  props: {
    currentSortingColumn: {
      type: Array,
      required: false,
      default: () => [],
    },
    sortableColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    rowCount: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      shadow: false,
    };
  },
  computed: {
    classes() {
      return {
        'shadow-sm': this.shadow,
      };
    },
  },
  watch: {
    loading() {
      const rect = this.$refs.table.getBoundingClientRect();

      if (rect.top < 0) {
        const scrollElement =
          window.document.scrollingElement ||
          window.document.body ||
          window.document.documentElement;
        const scrollTop =
          window.scrollY ||
          (document.documentElement.clientHeight
            ? document.documentElement.scrollTop
            : document.body.scrollTop) ||
          0;

        anime({
          targets: scrollElement,
          scrollTop: scrollTop + rect.top,
          duration: Math.max(500, Math.min(2000, -rect.top)),
          easing: 'cubicBezier(0.4, 0, 0.2, 1)',
        });
      }
    },
  },
  methods: {
    changeSortDirection(name) {
      this.$emit('sortColumnClicked', name);
    },
  },
};
