
import { mapGetters, mapActions } from 'vuex';
import { gifts, user, will } from '@/mixins/apollo';
import { giftsToGiftsAnalyticsParams } from '@/utilities/charity';

export default {
  name: 'PagesAffiliateWillGiftsIndex',
  mixins: [gifts, user, will],
  computed: {
    ...mapGetters('ruach', ['shouldShowCharityConsentPage']),
    ...mapGetters('gift', ['showGiftWizard']),
    isComplete() {
      return !!(this.willMeta.has_gifts === false || this.gifts.length);
    },
    submitLabel() {
      const noCharity = !this.gifts.find((g) => g.charity !== null);
      if (noCharity || this.willMeta.has_gifts === false) {
        return this.$t('forms.labels.continue');
      }
      return this.$t('forms.labels.continueToAssets');
    },
  },
  async mounted() {
    await this.getGiftsData();
  },
  methods: {
    ...mapActions('gift', ['addGift', 'editGift']),
    ...mapActions('will', ['getGiftsData']),
    getGiftDescription(gift, charityMeta) {
      return (
        gift?.recipientDirectoryPerson?.fullName ??
        charityMeta.displayName ??
        charityMeta.name
      );
    },
    async done() {
      if (this.willMeta.has_gifts === false && this.gifts.length) {
        await this.removeAllGifts();
      }
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'complete_module',
        props: {
          will_id: this.willId,
          module_name: 'Gifts',
        },
      });

      const noCharity = !this.gifts.find((g) => g.charity !== null);

      this.$nuxt.$emit('sendTrackingAttributes', {
        has_charity_gift: !noCharity,
      });
      const analyticsGiftsParams = giftsToGiftsAnalyticsParams(this.gifts);
      const flatAnalyticsGiftsParams = {};
      if (analyticsGiftsParams.recipient_type === 'charity') {
        flatAnalyticsGiftsParams[
          `charity_${analyticsGiftsParams.charity_name}_type_${analyticsGiftsParams.giftType}_value`
        ] = analyticsGiftsParams.gift_value;
      } else {
        flatAnalyticsGiftsParams[
          `individual_type_${analyticsGiftsParams.giftType}_value`
        ] = analyticsGiftsParams.gift_value;
      }

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'add_gifts',
        props: {
          gifts: analyticsGiftsParams,
          ...flatAnalyticsGiftsParams,
        },
      });

      if (noCharity) {
        this.$router.push({
          path: this.localePath('/will/gifts/include-charity'),
        });
      } else {
        this.willMeta.charity_in_gifts = 'true';
        await this.updateWillMeta();
        await this.refetchWill();
        await this.$updateShouldShowCharityConsent();
        const path = this.localePath(
          this.shouldShowCharityConsentPage.includes('gifts')
            ? '/will/charity-consent?next-module=assets'
            : '/will/assets'
        );
        this.$router.push({
          path,
        });
      }
    },
  },
};
