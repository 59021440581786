
import { mapActions } from 'vuex';

export default {
  name: 'AppGlossaryTooltip',
  props: {
    term: {
      type: String,
      required: true,
    },
  },
  computed: {
    classes() {
      return [
        'max-w-lg',
        'px-4',
        'py-3',
        'rounded',
        'bg-white',
        'shadow',
        'text-left',
        'text-charoal-300',
        'no-arrow',
      ];
    },
    content() {
      return `
        ${this.$t('glossary')[this.term].desc}
        <br>
        <div class="mt-2 font-bold text-sm text-teal-300 uppercase">
          ${this.$t('texts.sentences.clickToOpenGlossary')}
        <div>`;
    },
    popperOptions() {
      return {
        modifiers: {
          computeStyle: {
            gpuAcceleration: false,
          },
        },
      };
    },
  },
  methods: {
    ...mapActions('glossary', ['setShowGlossaryPanel']),
  },
};
