
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import { mapGetters } from 'vuex';
import { Portal, PortalTarget } from 'portal-vue';

import endOfLife from '@/mixins/end-of-life';
import files from '@/mixins/files';
import { toTitleCase } from '@/utilities';
import {
  FUNERAL_TYPE_OPTIONS,
  FUNERAL_DIRECTOR_OPTIONS,
  FUNERAL_PREPAID_TYPE_OPTIONS,
} from '@/utilities/end-of-life/form-constants';

const DEFAULT_LAYOUT = 'DEFAULT';

export default {
  name: 'PagesAffiliateEndOfLife',
  components: {
    Portal,
    PortalTarget,
  },
  mixins: [endOfLife, files],
  layout: 'ruach',
  data() {
    return {
      categoryTabIndex: null,
      subcategoryTabIndex: null,
      loading: false,
      isBusy: false,
      isInvalid: false,
      modalData: null,
      endOfLifeItemsByCategory: {},
      deleteEndOfLifeItemModalIsVisible: false,
      uploadFileModalIsOpen: false,
      unlinkEndOfLifeItemFileModalIsVisible: false,
      uploadedFiles: {
        item: {
          files: [],
          loading: null,
          alert: {
            message: null,
          },
        },
        category: {
          files: [],
          loading: null,
          alert: {
            message: null,
          },
        },
      },
      selectedUnlinkFile: null,
      fileTypes: [],
      questions: {
        FUNERAL_PREFERENCE: [
          {
            key: 'funeralType',
            name: 'Preferred funeral type',
            type: 'select',
            options: FUNERAL_TYPE_OPTIONS,
          },
          {
            key: 'funeralDirectorPreference',
            name: 'Preferred funeral director',
            type: 'select',
            options: FUNERAL_DIRECTOR_OPTIONS,
          },
          {
            key: 'funeralProvider',
            name: 'Preferred funeral provider',
            type: 'textarea',
            requirement: {
              key: 'funeralDirectorPreference',
              values: FUNERAL_DIRECTOR_OPTIONS,
            },
          },
          {
            key: 'restingPlacePreference',
            name: 'Final resting place',
            type: 'textarea',
          },
          {
            key: 'serviceLocation',
            name: 'Service location',
            type: 'textarea',
          },
          {
            key: 'eulogy',
            name: 'Eulogy, readings or songs',
            type: 'textarea',
          },
          {
            key: 'dressCode',
            name: 'Dress code',
            type: 'textarea',
          },
          {
            key: 'food',
            name: 'Food to be served',
            type: 'textarea',
          },
          {
            key: 'guestList',
            name: 'Guest list',
            type: 'textarea',
          },
          {
            key: 'other',
            name: 'Other notes',
            type: 'textarea',
          },
        ],
        FUNERAL_PREPAID_POLICY: [
          {
            key: 'type',
            name: 'Prepaid funeral policy type',
            type: 'select',
            options: FUNERAL_PREPAID_TYPE_OPTIONS,
          },
          {
            key: 'institution',
            name: 'Prepaid funeral policy institution',
            type: 'text',
          },
          {
            key: 'other',
            name: 'Other notes',
            type: 'textarea',
          },
        ],
      },
      FUNERAL_TYPE_OPTIONS,
      FUNERAL_DIRECTOR_OPTIONS,
      FUNERAL_PREPAID_TYPE_OPTIONS,
    };
  },
  computed: {
    ...mapGetters(['userId']),
    ...mapGetters('end-of-life-categories', [
      'endOfLifeCategoryTree',
      'endOfLifeCategorySlugsToIds',
      'endOfLifeCategoryItemCounts',
    ]),
    isQuestionLayout() {
      return ['FUNERAL_PREFERENCE', 'FUNERAL_PREPAID_POLICY'].includes(
        this.activeDataLayout
      );
    },
    fileUploadsAllowed() {
      return (
        !['FUNERAL_PREFERENCE', 'FUNERAL_PREPAID_POLICY', 'CONTACT'].includes(
          this.activeDataLayout
        ) && this.fileTypes.length > 0
      );
    },
    isLoading() {
      return (
        !this.endOfLifeCategoryTree.length &&
        !Object.keys(this.endOfLifeCategorySlugsToIds) &&
        !Object.keys(this.endOfLifeCategoryItemCounts)
      );
    },
    rootCategory() {
      return (
        this.endOfLifeCategories[
          this.endOfLifeCategorySlugsToIds[this.rootCategorySlug]
        ] || {}
      );
    },
    rootCategorySlug() {
      return this.$route.params.rootcategory;
    },
    categorySlug() {
      return this.$route.params.category;
    },
    subcategorySlug() {
      return this.$route.params.subcategory;
    },
    displayCategoryTree() {
      const rootCategory = this.endOfLifeCategoryTree.find(
        (category) => category.slug === this.rootCategorySlug
      );
      return rootCategory?.subcategories || [];
    },
    categoryDataHasLoaded() {
      return (
        !!this.endOfLifeCategoryTree &&
        !!this.endOfLifeCategoryTree?.length &&
        !!this.displayCategoryTree.length
      );
    },
    showCategoriesTabs() {
      return (
        this.endOfLifeCategoryTree.length && this.displayCategoryTree.length
      );
    },
    showSubcategoriesTabs() {
      if (!this.endOfLifeCategoryTree.length) {
        return false;
      }
      const category = this.displayCategoryTree.find(
        (category) => category.slug === this.categorySlug
      );
      return !!category?.subcategories?.length;
    },
    endOfLifeItemModalIsVisible() {
      return (
        this.modalData &&
        !this.deleteEndOfLifeItemModalIsVisible &&
        !this.uploadFileModalIsOpen &&
        !this.unlinkEndOfLifeItemFileModalIsVisible
      );
    },
    activeSubcategoryId() {
      const subcategory =
        this.$route.params.subcategory ||
        this.endOfLifeCategoryTree[0].subcategories[0]?.slug;
      if (!subcategory) {
        return null;
      }
      return this.endOfLifeCategorySlugsToIds[subcategory];
    },
    slugsJoined() {
      const joinedSlugOrder = [
        this.$route.params.rootcategory,
        this.$route.params.category,
        this.$route.params.subcategory,
      ];
      return (
        joinedSlugOrder
          .filter((slug) => slug)
          .join('__')
          .replaceAll('-', '_') || null
      );
    },
    activeDataLayout() {
      const joinedSlugToLayoutMapping = {
        personal__identification__birth_certificate: 'BIRTH_CERTIFICATE',
        personal__identification__citizenship: 'RESIDENCE',
        personal__identification__government_concession:
          'GOVERNMENT_CONCESSION',
        personal__identification__licence: 'LICENCE',
        personal__identification__medicare: 'MEDICARE',
        personal__identification__passport: 'PASSPORT',
        personal__identification__proof_of_residence: 'RESIDENCE',
        personal__identification__relationship: 'RELATIONSHIP',
        personal__identification__taxation: 'TAXATION',
        personal__identification__visa: 'RESIDENCE',
        legal__estate_planning__guardianship: 'GUARDIANSHIP',
        legal__estate_planning__power_of_attorney: 'POWER_OF_ATTORNEY',
        legal__estate_planning__will: 'WILL',
        financial__assets__bank_accounts: 'BANK_ACCOUNT',
        financial__assets__business: 'BUSINESS',
        financial__assets__financial_assets: 'FINANCIAL_ASSETS',
        financial__assets__insurances: 'INSURANCE',
        financial__assets__assets_other: DEFAULT_LAYOUT,
        financial__assets__properties: 'PROPERTY',
        financial__assets__superannuation: 'SUPERANNUATION',
        financial__assets__trust: 'TRUST',
        financial__assets__valuables: 'FINANCIAL_VALUABLE',
        financial__assets__vehicles: 'VEHICLE',
        financial__income__employment: 'EMPLOYMENT',
        financial__income__government_benefits: 'GOVERNMENT_BENEFIT',
        financial__liabilities__credit_cards: 'CREDIT_CARD',
        financial__liabilities__loans: 'LOAN',
        financial__liabilities__liabilities_other: DEFAULT_LAYOUT,
        financial__recurring__donations: 'DONATION',
        financial__recurring__subscriptions: 'SUBSCRIPTION',
        financial__recurring__utilities: 'UTILITY',
        medical__medical_equipment: 'MEDICAL_DEVICE',
        medical__organ_donation: 'ORGAN_DONATION',
        funeral__funeral_other: DEFAULT_LAYOUT,
        funeral__preferences: 'FUNERAL_PREFERENCE',
        funeral__prepaid_policies: 'FUNERAL_PREPAID_POLICY',
        emotional__message: 'EMOTIONAL_MESSAGE',
        emotional__story: 'EMOTIONAL_STORY',
        key_contacts__accountants: 'CONTACT',
        key_contacts__doctors: 'CONTACT',
        key_contacts__financial_advisors: 'CONTACT',
        key_contacts__lawyers: 'CONTACT',
        key_contacts__key_contacts_other: 'CONTACT',
      };
      return joinedSlugToLayoutMapping[this.slugsJoined] || DEFAULT_LAYOUT;
    },
    institutionType() {
      const slugToInstitutionType = {
        financial__assets__bank_accounts: 'Bank',
        financial__assets__superannuation: 'Superannuation',
        financial__assets__insurances: 'Insurance',
        financial__liabilities__credit_cards: 'Credit Card',
        financial__liabilities__loans: 'Loan',
        financial__income__government_benefits: 'Government Benefits',
        financial__recurring__internet: 'Internet',
        financial__recurring__mobiles: 'Mobile',
        financial__recurring__subscriptions: 'Subscription',
        financial__recurring__utilities: 'Utilities',
        liabilities__personal_loans: 'Personal Loan',
        financial__recurring__recurring_other: 'Subscription',
      };
      return slugToInstitutionType[this.slugsJoined] || '';
    },
    activeCategory() {
      if (this.subcategorySlug) {
        return this.endOfLifeCategories[
          this.endOfLifeCategorySlugsToIds[this.subcategorySlug]
        ];
      }
      if (this.categorySlug) {
        return this.endOfLifeCategories[
          this.endOfLifeCategorySlugsToIds[this.categorySlug]
        ];
      }
      if (this.rootCategorySlug) {
        return this.endOfLifeCategories[
          this.endOfLifeCategorySlugsToIds[this.rootCategorySlug]
        ];
      }
      return null;
    },
    unansweredQuestions() {
      const answeredQuestions =
        this.endOfLifeItemsByCategory[this.activeCategory.id]?.map(
          (item) => item.data
        ) || [];
      const answeredQuestionsKeys = new Set(
        answeredQuestions.map((item) => item.key)
      );
      return (
        this.questions[this.activeDataLayout]?.filter((question) => {
          if (!answeredQuestionsKeys.has(question.key)) {
            if (!question.requirement) {
              return true;
            } else {
              const requirementAnswer = answeredQuestions.find(
                (item) => item.key === question.requirement.key
              );
              if (
                requirementAnswer &&
                question.requirement.values.includes(requirementAnswer.value)
              ) {
                return true;
              }
            }
          }
          return false;
        }) || []
      );
    },
    currentQuestion() {
      if (!this.isQuestionCategory) {
        return null;
      }
      if (this.modalData?.type === 'update') {
        return this.questions[this.activeDataLayout].find(
          ({ key }) => key === this.modalData?.data?.key
        );
      }
      return this.unansweredQuestions[0] || null;
    },
    isQuestionCategory() {
      return ['FUNERAL_PREFERENCE', 'FUNERAL_PREPAID_POLICY'].includes(
        this.activeDataLayout
      );
    },
    addItemButtonIsVisible() {
      return !this.isQuestionCategory || this.unansweredQuestions?.length > 0;
    },
    questionNextText() {
      if (this.unansweredQuestions?.length === 1) {
        if (!this.modalData?.data?.value) {
          return 'Skip and close';
        } else {
          return 'Save and close';
        }
      }
      if (!this.modalData?.data?.value) {
        return 'Skip question';
      }
      return 'Next question';
    },
    filesTypeString() {
      const filesTypeString = this.fileTypes
        .map((fileType) => {
          return startCase(camelCase(fileType.text));
        })
        .join(', ');
      const comma = ', ';
      const position = filesTypeString.lastIndexOf(comma);
      if (position === -1) {
        return filesTypeString;
      }
      return (
        filesTypeString.substring(0, position) +
        ', or ' +
        filesTypeString.substring(position + comma.length)
      );
    },
    categorySlugs() {
      let category = null;
      let subcategory = null;
      const rootcategory =
        this.endOfLifeCategoryTree.find(
          (category) => category.slug === this.rootCategorySlug
        ) || this.endOfLifeCategoryTree[0];
      if (rootcategory) {
        category =
          rootcategory.subcategories.find(
            (category) => category.slug === this.categorySlug
          ) || rootcategory?.subcategories[0];
      }
      if (category) {
        subcategory =
          category.subcategories.find(
            (category) => category.slug === this.subcategorySlug
          ) || category?.subcategories[0];
      }

      return [rootcategory?.slug, category?.slug, subcategory?.slug].filter(
        (category) => category
      );
    },
  },
  watch: {
    categoryDataHasLoaded(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setCategories();
      }
    },
    '$route.params': {
      handler: 'setCategories',
      immediate: true,
    },
    endOfLifeCategoryTree(newValue) {
      if (newValue && !this.$route.params.rootcategory) {
        this.setCategories();
      }
    },
  },
  methods: {
    async setCategories() {
      if (!this.endOfLifeCategoryTree.length) {
        return;
      }

      if (
        this.categorySlugs[0] !== this.rootCategorySlug ||
        this.categorySlugs[1] !== this.categorySlug ||
        this.categorySlugs[2] !== this.subcategorySlug
      ) {
        this.$router.push({
          path: this.localePath(`/end-of-life/${this.categorySlugs.join('/')}`),
        });
        return;
      }
      if (!this.displayCategoryTree.length || this.loading) {
        return;
      }
      this.loading = true;
      const categorySlug =
        this.categorySlug || this.displayCategoryTree[0].slug;
      const category = this.displayCategoryTree.find(
        (category) => category.slug === categorySlug
      );
      if (category && !category?.subcategories?.length) {
        await this.refetchCategoryItems(category.id);
      } else if (category && category?.subcategories?.length) {
        const subcategorySlug =
          this.$route.params.subcategory ||
          this.displayCategoryTree[0].subcategories[0].slug;
        const subcategoryId = this.endOfLifeCategorySlugsToIds[subcategorySlug];
        await this.refetchCategoryItems(subcategoryId);
      }
      this.getFileTypes();
      this.getAllUploadedFiles();
      this.updateTabsIndexes();
      this.loading = false;
    },
    updateTabsIndexes() {
      if (!this.rootCategorySlug || !this.categorySlug) {
        return;
      }
      const categoryIndex = this.displayCategoryTree.findIndex(
        (category) => category.slug === this.categorySlug
      );
      const subcategoryIndex = this.displayCategoryTree[
        categoryIndex
      ].subcategories.findIndex(
        (subcategory) => subcategory.slug === this.subcategorySlug
      );
      this.categoryTabIndex = categoryIndex;
      this.subcategoryTabIndex = subcategoryIndex;
    },
    categoryTabSelected(categoryIndex, subcategoryIndex) {
      let redirectPath = `/end-of-life/${this.rootCategorySlug}`;
      const categorySlug = this.displayCategoryTree[categoryIndex].slug;
      const subcategorySlug =
        this.displayCategoryTree[categoryIndex].subcategories[subcategoryIndex]
          ?.slug;
      if (subcategorySlug) {
        redirectPath += `/${categorySlug}/${subcategorySlug}`;
      } else {
        redirectPath += `/${categorySlug}`;
      }
      this.$router.push({ path: this.localePath(redirectPath) });
    },
    async getEndOfLifeItems(categoryId, dataLayout) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      const endOfLifeItems = await this.getEndOfLifeItemsByCategoryIdAndUserId(
        categoryId,
        this.userId
      );

      return await Promise.all(
        endOfLifeItems.map((item) =>
          this.revealPrivacyVaultData(item, dataLayout)
        )
      );
    },
    revealPrivacyVaultData(endOfLifeItem, dataLayout) {
      return this.$sensitive.EndOfLifeItem[dataLayout].reveal(
        endOfLifeItem.id,
        endOfLifeItem
      );
    },
    addItem(categoryId, categoryName) {
      this.modalData = {
        type: 'CREATE_VAULT',
        header: `Add ${categoryName}`,
        categoryId,
        data: {},
        fileIds: [],
      };
    },
    closeModal() {
      this.modalData = null;
    },
    async saveItem() {
      const { categoryId } = this.modalData;
      let snackbarText;
      this.isBusy = true;
      try {
        if (this.modalData.type === 'CREATE_VAULT') {
          snackbarText = 'Successfully added';
          const newEndOfLifeItem =
            await this.createEndOfLifeItemWithSensitiveData({
              userId: this.userId,
              categoryId,
              dataLayout: this.activeDataLayout,
              itemData: this.modalData.data,
              fileIds: this.modalData.fileIds,
            });

          this.$set(this.endOfLifeItemsByCategory, categoryId, [
            ...(this.endOfLifeItemsByCategory[categoryId] || []),
            await this.revealPrivacyVaultData(
              newEndOfLifeItem,
              this.activeDataLayout
            ),
          ]);
        } else if (this.modalData.type === 'update') {
          snackbarText = 'Successfully updated';
          const updatedItem = await this.updateEndOfLifeItemWithSensitiveData({
            itemId: this.modalData.id,
            categoryId: this.modalData.categoryId,
            dataLayout: this.activeDataLayout,
            userId: this.modalData.userId,
            itemData: this.modalData.data,
            fileIds: this.modalData.fileIds,
          });
          const currentEndOfLifeItemIndex = this.endOfLifeItemsByCategory[
            this.modalData.categoryId
          ].findIndex((item) => item.id === updatedItem.id);
          this.endOfLifeItemsByCategory[this.modalData.categoryId].splice(
            currentEndOfLifeItemIndex,
            1,
            await this.revealPrivacyVaultData(
              updatedItem,
              this.activeDataLayout
            )
          );
        }
        if (this.isQuestionCategory && this.modalData.type === 'CREATE_VAULT')
          return;
        this.isBusy = false;
        this.$nuxt.$emit('snackbar', {
          text: snackbarText,
          type: 'success',
          icon: 'success',
        });
        this.closeModal();
      } catch {
        this.isBusy = false;
        this.closeModal();
        this.$nuxt.$emit('snackbar', {
          text: 'Something went wrong, please try again',
          type: 'error',
          icon: 'error',
        });
      }
    },
    editItem(categoryId, itemId) {
      const item = this.endOfLifeItemsByCategory[categoryId].find(
        (item) => item.id === itemId
      );
      const data = item.data;
      this.modalData = {
        header: `Update ${data.name}`,
        id: itemId,
        categoryId,
        userId: this.userId,
        data: {
          ...data,
        },
        fileIds: item.fileIds,
        type: 'update',
      };
      this.getAllUploadedFiles();
    },
    async deleteItem(categoryId, itemId) {
      await this.deleteEndOfLifeItem(categoryId, itemId);
      this.endOfLifeItemsByCategory[categoryId] = this.endOfLifeItemsByCategory[
        categoryId
      ].filter((item) => item.id !== itemId);
      this.closeDeleteEndOfLifeItemModal();
      this.$nuxt.$emit('snackbar', {
        text: 'Successfully deleted',
        type: 'success',
        icon: 'success',
      });
      this.closeModal();
    },
    openDeleteEndOfLifeItemModal() {
      this.deleteEndOfLifeItemModalIsVisible = true;
    },
    closeDeleteEndOfLifeItemModal() {
      this.deleteEndOfLifeItemModalIsVisible = false;
    },
    openUploadFileModal() {
      this.uploadFileModalIsOpen = true;
    },
    closeUploadFileModal() {
      this.uploadFileModalIsOpen = false;
    },
    openUnlinkEndOfLifeItemFileModal(fileId) {
      this.unlinkEndOfLifeItemFileId = fileId;
      this.unlinkEndOfLifeItemFileModalIsVisible = true;
    },
    closeUnlinkEndOfLifeItemFileModal() {
      this.unlinkEndOfLifeItemFileModalIsVisible = false;
    },
    async uploadFiles(files, fileType, fileName) {
      if (this.modalData) {
        this.uploadedFiles.item.alert.message = null;
        this.uploadedFiles.item.loading = true;
        this.uploadEndOfLifeItemFiles({
          files,
          fileType,
          fileName,
        });
      } else {
        this.uploadedFiles.category.alert.message = null;
        this.uploadedFiles.category.loading = true;
        const { success } = await this.uploadEndOfLifeCategoryFiles({
          categoryId: this.activeSubcategoryId,
          files,
          fileType,
          fileName,
        });
        if (success) {
          await this.getAllUploadedFiles();
        } else {
          this.uploadedFiles.category.alert.message =
            'Oh no! Something went wrong while uploading your files.';
        }
        this.uploadedFiles.category.loading = false;
      }
    },
    async getAllUploadedFiles() {
      if (!this.activeSubcategoryId) {
        return;
      }
      this.uploadedFiles.category.loading = true;
      this.uploadedFiles.category.alert.message = null;
      this.uploadedFiles.category.files = [];
      try {
        this.uploadedFiles.category.files = [
          ...(await this.getEOLCategoryFiles(this.activeSubcategoryId)),
        ].reverse();
      } catch {
        this.uploadedFiles.category.alert.message =
          'Oh no! Something went wrong while fetching your uploaded files.';
      } finally {
        this.uploadedFiles.category.loading = false;
      }

      this.uploadedFiles.item.loading = true;
      this.uploadedFiles.item.alert.message = null;
      this.uploadedFiles.item.files = [];
      try {
        const fileIds = this.modalData?.fileIds || [];
        const files = await this.getFilesByIds(fileIds);
        this.uploadedFiles.item.files = [
          ...files.map((file) => ({
            ...file,
            unlinkable: true,
            mutable: false,
          })),
        ].reverse();
      } catch {
        this.uploadedFiles.item.alert.message =
          'Oh no! Something went wrong while fetching your uploaded files.';
      } finally {
        this.uploadedFiles.item.loading = false;
      }
    },
    async uploadEndOfLifeItemFiles({ files, fileType, fileName }) {
      this.uploadedFiles.item.loading = true;
      this.uploadedFiles.item.alert.message = null;
      const uploadedFileIds = this.modalData?.fileIds || [];
      try {
        await Promise.all(
          Object.values(files).map(async (file) => {
            const getFileUploadUrlData = await this.getFileUploadUrl(
              file,
              fileType,
              fileName
            );
            await this.uploadFile(getFileUploadUrlData.url, file);
            await this.associateUploadedFile(getFileUploadUrlData.id, 'NONE');
            uploadedFileIds.push(getFileUploadUrlData.id);
          })
        );
        this.modalData.fileIds = uploadedFileIds;
      } catch {
        this.uploadedFiles.item.alert.message =
          'Oh no! Something went wrong while fetching your uploaded files.';
      } finally {
        this.uploadedFiles.item.loading = false;
      }
      this.getAllUploadedFiles();
    },
    unlinkEndOfLifeItemFile() {
      this.modalData.fileIds = this.modalData.fileIds.filter(
        (id) => !this.unlinkEndOfLifeItemFileId.includes(id)
      );
      this.uploadedFiles.item.files = this.uploadedFiles.item.files.filter(
        (file) => !this.unlinkEndOfLifeItemFileId.includes(file.id)
      );
      this.closeUnlinkEndOfLifeItemFileModal();
    },
    async getFileTypes() {
      const fileTypes = await this.getFileTypesByCategoryId(
        this.activeCategory.id
      );
      this.fileTypes = fileTypes
        .sort((a, b) => {
          if (a.key < b.key) {
            return -1;
          }
          if (a.key > b.key) {
            return 1;
          }
          return 0;
        })
        .map((fileType) => ({
          value: fileType.key,
          text: toTitleCase(fileType.key),
        }));
    },
    async unlinkEndOfLifeCategoryFile(fileId) {
      this.uploadedFiles.category.loading = true;
      this.uploadedFiles.category.alert.message = null;
      try {
        await this.deleteEOLCategoryFile({
          categoryId: this.activeSubcategoryId,
          fileId,
        });
      } catch {
        this.uploadedFiles.category.alert.message =
          'Oh no! Something went wrong while deleting your uploaded file.';
      } finally {
        this.uploadedFiles.category.loading = false;
      }

      this.uploadedFiles.category.files =
        this.uploadedFiles.category.files.filter((file) => file.id !== fileId);
    },
    async refetchCategoryItems(categoryId) {
      await this.$set(
        this.endOfLifeItemsByCategory,
        categoryId,
        await this.getEndOfLifeItems(categoryId, this.activeDataLayout)
      );
    },
    async saveAnswer() {
      await this.saveItem();
      if (this.unansweredQuestions?.length === 0) {
        this.closeModal();
        return;
      }
      this.modalData = {
        type: 'CREATE_VAULT',
        header: `Add ${this.activeCategory.name}`,
        categoryId: this.activeCategory.id,
        data: {
          key: this.currentQuestion.key,
          name: this.currentQuestion.name,
          value: null,
        },
      };
    },
    setItemField(key, value) {
      this.modalData.data[key] = value;
    },
  },
};
