
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import endOfLife from '@/mixins/end-of-life';
import endOfLifeAdmin from '@/mixins/end-of-life-admin';
import { recursiveRemoveKey } from '@/utilities';
export default {
  name: 'PagesAffiliateAdminEndOfLifeCategoriesId',
  components: {
    ValidationObserver,
  },
  mixins: [endOfLife, endOfLifeAdmin],
  layout: 'admin',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('end-of-life-categories', ['endOfLifeCategories']),
    parentCategoryOptions() {
      return (
        Object.keys(this.endOfLifeCategories)
          .map((category) => ({
            text: this.endOfLifeCategories[category].name,
            value: this.endOfLifeCategories[category].id,
          }))
          .sort((a, b) => {
            if (a.text < b.text) return -1;
            if (a.text > b.text) return 1;
            return 0;
          }) || []
      );
    },
  },
  created() {
    this.getEndOfLifeCategories();
  },
  methods: {
    ...mapActions('end-of-life-categories', ['getEndOfLifeCategories']),
    async updateEndOfLifeCategoryData() {
      this.loading = true;
      const isValid =
        this.$refs.observer && (await this.$refs.observer.validate());
      if (!isValid) {
        this.loading = false;
        return;
      }
      const newEndOfLifeCategory = { ...this.endOfLifeCategory };
      recursiveRemoveKey(newEndOfLifeCategory, '__typename');
      recursiveRemoveKey(newEndOfLifeCategory, 'createdAt');
      recursiveRemoveKey(newEndOfLifeCategory, 'updatedAt');
      await this.updateEndOfLifeCategory(newEndOfLifeCategory);
      this.loading = false;
      this.getEndOfLifeCategories(true);
    },
  },
};
