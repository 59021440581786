
import { mapGetters } from 'vuex';
import { PLAN_FLAG_STATUS } from '@/utilities/constants';

export default {
  name: 'AppModuleSummary',
  model: {
    event: 'change',
  },
  props: {
    flagsAreDisabled: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      default: '',
      type: [String, Boolean],
    },
    module: {
      required: true,
      type: String,
    },
    willId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      done: true,
      expanded: true,
      flagged: this.value,
    };
  },
  computed: {
    ...mapGetters('flag', ['flags', 'planFlags']),
    modulePlanFlags() {
      return this.planFlags
        .filter(({ planSegment }) => planSegment.module === this.module)
        .sort((planFlagA, planFlagB) => {
          // Active flags should be listed first
          return planFlagB.status === PLAN_FLAG_STATUS.ACTIVE ? 1 : -1;
        });
    },
  },
  watch: {
    value(newValue) {
      if (!newValue) this.flagged = false;
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
  },
};
