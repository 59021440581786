
import VDismiss from 'vue-dismiss';
import { debounce } from 'throttle-debounce';
import kebabCase from 'lodash/kebabCase';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AppSnowflakesProductNavigation',
  mixins: [VDismiss],
  data() {
    return {
      expandedProduct: null,
      productTitle: null,
      routes: {},
    };
  },
  computed: {
    ...mapGetters(['userId', 'willStatus']),
    ...mapGetters('directory-person', ['userDetails', 'userIsAustralian']),
    ...mapGetters('affiliate', ['features', 'slug']),
    ...mapGetters('end-of-life-categories', ['endOfLifeCategoryTree']),
    ...mapGetters('modules', ['modules', 'poaModules']),
    ...mapGetters('subscription', {
      subscriptionIsValid: 'isValid',
    }),
    ...mapGetters('poa', {
      poaStatus: 'status',
      poaId: 'poaId',
    }),
    ...mapGetters('progress', { willProgress: 'will', poaProgress: 'poa' }),
    ...mapGetters('ruach', ['activeProduct', 'activeSection']),
    ...mapGetters('flag', ['isPlanModuleFlagged']),
    ...mapGetters('will-tiers', ['isRecommendationPageAvailable']),
    status() {
      if (!Object.keys(this.routes).length > 0) {
        return '...';
      }
      const moduleLength = this.routes[this.activeProduct].length;
      const moduleIndex = this.routes[this.activeProduct].findIndex(
        (moduleRoute) => {
          return this.$route.path.includes(moduleRoute);
        }
      );
      if (moduleIndex > -1) {
        return `Step ${moduleIndex + 1} of ${moduleLength}`;
      } else {
        return this.productTitle;
      }
    },
    progressIndicators() {
      return {
        will: this.willProgress.modules,
        poa: this.poaProgress.modules,
      };
    },
    hasEndOfLifeItems() {
      return this.endOfLifeCategoryItemCounts &&
        Object.keys(this.endOfLifeCategoryItemCounts)
        ? Object.keys(this.endOfLifeCategoryItemCounts).some(
            (category) => this.endOfLifeCategoryItemCounts[category] > 0
          )
        : false;
    },
    products() {
      return [
        ...(this.features.willEnabled
          ? [
              {
                name: 'will',
                title: this.$t('components.willGateway.title'),
                href: '/will',
                hasIcons: true,
                isLocked:
                  !this.subscriptionIsValid ||
                  this.willStatus !== 'IN_PROGRESS',
                modules: this.modules.map(
                  ({ key, flagKey, displayName, path }) => ({
                    key,
                    title: this.$t(displayName),
                    href: this.getWillModulePath({ key, path }),
                    icon: this.progressIndicators.will[key].icon,
                    class: this.progressIndicators.will[key].class,
                    decimal: this.progressIndicators.will[key].decimal,
                    flagged:
                      this.isPlanModuleFlagged(flagKey) &&
                      (this.willStatus === 'IN_PROGRESS' ||
                        this.willStatus === 'FLAGGED'),
                  })
                ),
              },
            ]
          : []),
        ...(this.features.powerOfAttorneyEnabled
          ? [
              {
                name: 'poa',
                title: this.$t('components.powerOfAttorneyGateway.title'),
                href: this.poaId ? '/poa' : '/onboarding/poa',
                hasIcons: true,
                isLocked: !this.poaId,
                modules: !this.userIsAustralian
                  ? []
                  : this.poaModules.map(({ key, displayName, path: href }) => ({
                      key,
                      title: this.$t(displayName),
                      href,
                      icon: this.progressIndicators.poa[key].icon,
                      class: this.progressIndicators.poa[key].class,
                      decimal: this.progressIndicators.poa[key].decimal,
                    })),
              },
            ]
          : []),
        ...(this.features.endOfLifeEnabled
          ? [
              {
                name: 'endOfLife',
                title: this.$t('components.vaultGateway.title'),
                href:
                  !this.hasEndOfLifeItems || !this.endOfLifeCategoryTree.length
                    ? '/onboarding/end-of-life'
                    : `/end-of-life`,
                hasIcons: false,
                isLocked: false,
                modules: this.endOfLifeCategoryTree.map((category) => {
                  return {
                    title: category.name,
                    href: `/end-of-life/${category.slug}`,
                  };
                }),
              },
            ]
          : []),
      ];
    },
  },
  watch: {
    expandedProduct(expandedProduct) {
      document.body.classList.toggle(
        '--product-navigation-is-open',
        !!expandedProduct
      );
    },
  },
  async mounted() {
    await this.loadToolAndVaultItems();
    this.routes = this.products.reduce(
      (newObject, { name, title, modules }) => {
        if (!this.productName && name === this.activeProduct) {
          this.productTitle = title;
        }
        newObject[name] = modules.map((module) => module.href);
        return newObject;
      },
      {}
    );
    this.measureAndAdjust();
    window.addEventListener('resize', this.measureAndAdjust);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.measureAndAdjust);
  },
  methods: {
    ...mapActions('user-onboarding', ['loadToolAndVaultItems']),
    kebabCase,
    measureAndAdjust: debounce(100, function () {
      if (
        this.$refs.productStatus &&
        window.getComputedStyle(this.$refs.productStatus).display === 'none'
      ) {
        this.toggleExpandedProductIfExpanded();
      }
    }),
    toggleExpandedProduct(product) {
      if (this.expandedProduct === product) {
        this.expandedProduct = null;
      } else {
        this.expandedProduct = product;
      }
    },
    toggleExpandedProductIfExpanded() {
      if (this.expandedProduct) {
        this.toggleExpandedProduct();
      }
    },
    navigateAndTrack({ target }, href, product, module) {
      const blurTarget = 'BUTTON';
      let elementToBlur = target;
      if (target.tagName.toUpperCase() !== blurTarget) {
        elementToBlur = target.closest(blurTarget);
      }
      elementToBlur.blur();
      let elementId = `product-navigation__${kebabCase(product)}`;
      if (module) {
        elementId += `--${kebabCase(module)}`;
      }
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'click',
        props: {
          user_id: this.userId,
          element_id: elementId,
          page_path: this.$route.path,
        },
      });
      this.$router.push({
        path: this.localePath(href),
      });
    },
    getWillModulePath(module) {
      if (module?.key === 'review' && this.isRecommendationPageAvailable) {
        return '/will/review/recommendation';
      }
      return module.path;
    },
  },
};
