
import RuLogo from 'ruach/atoms/Logo';
import { mapActions, mapGetters } from 'vuex';
import { formatPrice } from '@/utilities';

export default {
  name: 'AppCartItem',
  components: {
    RuLogo,
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    hideItemAction: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    autoRenew: true,
  }),
  computed: {
    ...mapGetters('will-tiers', [
      'isWillTierTwoUpgradeAvailable',
      'isWillTierTwoDowngradeAvailable',
    ]),
    ...mapGetters('cart', [
      'cartItemFinalPrice',
      'cartItemBasePrice',
      'isFreeCart',
      'isCartEmpty',
      'subscriptionAutoRenewal',
      'showSubscriptionAutoRenewalInCheckout',
    ]),
    i18nBasePath() {
      if (
        this.isCardlessCheckout &&
        this.$te(`cart.cardlessProducts.${this.item.product.code}`)
      ) {
        return `cart.cardlessProducts.${this.item.product.code}`;
      }

      if (this.isSubscriptionItemVariantB) {
        return `cart.products.${this.item.product.code}.variantB`;
      }

      return `cart.products.${this.item.product.code}`;
    },
    basePriceIsVisible() {
      return this.basePrice !== this.finalPrice;
    },
    discountText() {
      return this.cartItemBasePrice(this.item.product.code) === 0 &&
        this.$te(`${this.i18nBasePath}.freePriceDiscount`)
        ? this.$t(`${this.i18nBasePath}.freePriceDiscount`)
        : this.$t(`${this.i18nBasePath}.discount`);
    },
    itemIsDiscounted() {
      return (
        (this.basePriceIsVisible &&
          this.$te(`cart.products.${this.item.product.code}.discount`)) ||
        (this.cartItemBasePrice(this.item.product.code) === 0 &&
          this.$te(`cart.products.${this.item.product.code}.freePriceDiscount`))
      );
    },
    supplierIsVisible() {
      return this.item.product.supplier === 'SAFEWILL_LEGAL';
    },
    basePrice() {
      const i18nDefaultBasePrice = `cart.products.${this.item.product.code}.fakePrice`;
      if (this.$te(i18nDefaultBasePrice)) {
        return this.$t(i18nDefaultBasePrice);
      }
      return formatPrice(this.cartItemBasePrice(this.item.product.code), false);
    },
    finalPrice() {
      if (this.isProductSubscription) {
        return this.$t(`cart.products.SUBSCRIPTION.fakeFinalPrice`);
      }
      return formatPrice(
        this.cartItemFinalPrice(this.item.product.code),
        false
      );
    },
    isCartItemRemovable() {
      return !this.isProductSubscription;
    },
    isWillProduct() {
      return this.item.product.code === 'WILL';
    },
    isWillTierTwoProduct() {
      return this.item.product.code === 'WILL_TIER_TWO';
    },
    isProductSubscription() {
      return this.item.product.code === 'SUBSCRIPTION';
    },
    emptyCartWithAutoRenewUpsell() {
      return this.showSubscriptionAutoRenewalInCheckout && this.isCartEmpty;
    },
    isCardlessCheckout() {
      return (
        this.$ff.cardlessCheckout() &&
        (this.isFreeCart || this.emptyCartWithAutoRenewUpsell)
      );
    },
    isCardlessCheckoutSubscription() {
      return (
        (this.isCardlessCheckout || this.emptyCartWithAutoRenewUpsell) &&
        this.isProductSubscription
      );
    },
    isSubscriptionItemVariantB() {
      return (
        this.isProductSubscription &&
        this.$ff.cartSubscriptionItemVariantB() &&
        !this.isCardlessCheckout
      );
    },
  },
  watch: {
    autoRenew(newValue) {
      this.setSubscriptionAutoRenewal(newValue);
    },
    subscriptionAutoRenewal(newValue) {
      if (newValue !== this.autoRenew) {
        this.autoRenew = newValue;
      }
    },
  },
  mounted() {
    this.autoRenew = this.subscriptionAutoRenewal;
  },
  methods: {
    ...mapActions('cart', ['removeFromCart', 'setSubscriptionAutoRenewal']),
    async removeItem(item) {
      await this.removeFromCart(item.product.code);
    },
  },
};
