
import { mapGetters } from 'vuex';
import { guardians, user, will } from '@/mixins/apollo';
import { RELATIONSHIP_TYPE } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateWillGuardiansBackup',
  mixins: [guardians, user, will],
  data() {
    return {
      RELATIONSHIP_TYPE,
      selectedContacts: [],
    };
  },
  computed: {
    ...mapGetters('directory-person', ['contacts']),
    isComplete() {
      return !!(
        this.willMeta.has_backup_guardian === false ||
        this.selectedContacts.length
      );
    },
    excludedContacts() {
      return this.primaryGuardians.map((guardian) => {
        return guardian.directoryPerson.id;
      });
    },
    variables() {
      return {
        id: this.willId,
        meta: this.willMeta.toArray(),
      };
    },
  },
  watch: {
    backupGuardians() {
      this.updateSelectedContact();
    },
  },
  mounted() {
    this.updateSelectedContact();
  },
  methods: {
    updateSelectedContact() {
      if (this.backupGuardians?.length) {
        this.selectedContacts = this.contacts
          .filter((contact) => {
            return this.isBackupGuardian(contact);
          })
          .map((contact) => contact.id);
      }
    },
    async done() {
      const contactsToRemove = [];
      const contactsToAdd = [];

      for (const contact of this.contacts) {
        const isBackupGuardian = this.isBackupGuardian(contact);
        const isSelected = this.selectedContacts.includes(contact.id);

        if (this.willMeta.has_backup_guardian && isSelected) {
          if (!isBackupGuardian) {
            contactsToAdd.push(contact);
          }
        } else if (isBackupGuardian) {
          contactsToRemove.push(contact);
        }
      }

      try {
        await Promise.all(
          contactsToRemove.map((contact) => this.removeGuardian(contact))
        );
        await Promise.all(
          contactsToAdd.map((contact) => this.addGuardian(contact, 'backup'))
        );
        this.$router.push({
          path: this.localePath('/will/guardians/your-pets'),
        });
      } catch (error) {
        console.error(error);
        this.$refs.form.setGenericError('assigningContacts');
      }
    },
  },
};
