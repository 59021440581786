
import { ValidationObserver } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'PagesAffiliateOnboardingUserDetails',
  components: {
    ValidationObserver,
  },
  layout: 'ruach',
  computed: {
    ...mapGetters('user-onboarding', ['currentStepData', 'loading']),
    ...mapFields('user-onboarding', [
      'payload.dateOfBirth',
      'payload.affiliateUserId',
      'payload.couponCode',
    ]),
    ...mapGetters('affiliate', ['features']),
  },
  methods: {
    ...mapActions('user-onboarding', ['saveDobAndAffiliateData']),
  },
};
