
import { mapGetters, mapActions } from 'vuex';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillFuneralIndex',
  mixins: [user, will],
  data() {
    return {
      options: [
        [
          {
            label: this.$t('titles.cremation'),
            value: 'cremation',
          },
        ],
        [
          {
            label: this.$t('titles.burial'),
            value: 'burial',
          },
        ],
        [
          {
            label: this.$t('pages.will.funeral.bodyDonation'),
            value: 'donate',
          },
          {
            label: this.$t('pages.will.funeral.executorDecision'),
            value: 'executor',
          },
        ],
      ],
    };
  },
  computed: {
    ...mapGetters('directory-person', ['userIsAustralian']),
    ...mapGetters('progress', { willProgress: 'will' }),
    ...mapGetters('will-tiers', ['isRecommendationPageAvailable']),
    prepaidCremationAddOnIsVisible() {
      return (
        this.willMeta?.funeral_type === 'cremation' && this.userIsAustralian
      );
    },
    burialAddOnIsVisible() {
      return this.willMeta?.funeral_type === 'burial' && this.userIsAustralian;
    },
    funeralTypeHasLocation() {
      return ['cremation', 'burial'].includes(this.willMeta.funeral_type);
    },
    nextStep() {
      if (this.willMeta.funeral_skip) {
        if (this.isRecommendationPageAvailable) {
          return '/will/review/recommendation';
        }
        return '/will/review';
      }
      return this.funeralTypeHasLocation
        ? '/will/funeral/location'
        : '/will/funeral/other-requests';
    },
    submitLabel() {
      return this.willMeta.funeral_type
        ? this.$t('forms.labels.continue')
        : this.$t('forms.labels.skip');
    },
  },
  mounted() {
    this.loadToolAndVaultItems();
  },
  methods: {
    ...mapActions('user-onboarding', ['loadToolAndVaultItems']),
    async onSubmit() {
      this.willMeta.funeral_skip = !this.willMeta.funeral_type;
      this.willMeta.funeral_location =
        this.willMeta.funeral_skip || !this.funeralTypeHasLocation
          ? null
          : this.willMeta.funeral_location;
      this.willMeta.funeral_note = this.willMeta.funeral_skip
        ? null
        : this.willMeta.funeral_note;

      if (this.willMeta.funeral_skip) {
        this.willMeta.funeral_type = 'executor';
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'complete_module',
          props: {
            will_id: this.willId,
            module_name: 'Funeral',
          },
        });
      }

      await this.updateWillMeta();
      const path = this.localePath(this.nextStep);
      this.$router.push({
        path,
      });
    },
  },
};
