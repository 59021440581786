
import confirm from '@/mixins/confirm';
import institutionsAdmin from '@/mixins/institutions-admin';
import { recursiveRemoveKey } from '@/utilities';

export default {
  name: 'PagesAffiliateAdminInstitutionsId',
  mixins: [institutionsAdmin, confirm],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    typeIsOther() {
      return this.institution.type === 'Other';
    },
    type() {
      return this.typeIsOther
        ? this.institution.typeOther
        : this.institution.type;
    },
  },
  methods: {
    showArchiveInstitutionModal() {
      this.$confirm(
        {
          title: `Archive ${this.institution.name}?`,
          message: 'Are you sure you want to archive this institution?',
        },
        async (action) => {
          if (action) {
            await this.archiveInstitution();
            this.$router.push(this.localePath('/admin/institutions'));
          }
        }
      );
    },
    async updateInstitutionData() {
      this.loading = true;
      const newInstitution = { ...this.institution, type: this.type };
      recursiveRemoveKey(newInstitution, '__typename');
      recursiveRemoveKey(newInstitution, 'createdAt');
      recursiveRemoveKey(newInstitution, 'updatedAt');

      await this.updateInstitution(newInstitution);

      this.loading = false;
    },
  },
};
