import { render, staticRenderFns } from "./end-of-life.vue?vue&type=template&id=438b70a0"
import script from "./end-of-life.vue?vue&type=script&lang=js"
export * from "./end-of-life.vue?vue&type=script&lang=js"
import style0 from "./end-of-life.vue?vue&type=style&index=0&id=438b70a0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppProductNavigation: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/snowflakes/ProductNavigation.vue').default,RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppLoading: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Loading.vue').default,AppTabs: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Tabs.vue').default,AppEndOfLifeItemList: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/end-of-life/ItemList.vue').default,AppImage: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Image.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,AppFileThumbnail: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/FileThumbnail.vue').default,AppEndOfLifeFieldset: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/end-of-life/Fieldset.vue').default,AppFilesList: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/FilesList.vue').default,AppTip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Tip.vue').default,AppFormControl: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/FormControl.vue').default,AppFormWrapper: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/FormWrapper.vue').default,AppModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Modal.vue').default,AppUploadFileModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/UploadFileModal.vue').default})
