
import { deviceType } from 'detect-it';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import cookies from 'js-cookie';
import { user } from '@/mixins/apollo';
import { sanitizeSignUpRedirect } from '@/utilities/allowed-redirects';
import {
  retrieveUtmQueryParameters,
  saveAnonymousProfile,
  getAnonymousProfile,
} from '@/utilities';

export default {
  name: 'PagesAffiliateGetStarted',
  mixins: [user],
  middleware: ['redirect-if-logged-in.middleware'],
  computed: {
    ...mapFields('signup', [
      'meta.referral_charity',
      'meta.user_agent',
      'meta.device_type',
      'meta.has_partner',
    ]),
  },
  mounted() {
    if (Object.keys(this.$route.query).length) {
      if (this.$route.query.charity) {
        this.referral_charity = this.$route.query.charity;
        cookies.set('referral_charity', this.referral_charity);
        saveAnonymousProfile({ referralCharity: this.referral_charity });
      }

      if (this.$route.query.coupon) {
        cookies.set('coupon', this.$route.query.coupon);
        saveAnonymousProfile({
          couponCode: this.$route.query.coupon,
        });
      }

      if (this.$route.query.hasPartner) {
        this.has_partner = true;
        cookies.set('has_partner', true);
      }

      if (this.$route.query.redirect) {
        const where = sanitizeSignUpRedirect(
          String(this.$route.query.redirect)
        );

        if (where) {
          cookies.set('signup_redirect', where);
        }
      }
      this.$router.replace({ path: this.localePath(this.$route.path) });
    }

    const utmParams =
      retrieveUtmQueryParameters(this.$route.query) ??
      getAnonymousProfile()?.utm;
    const hasUtmCookie = cookies.get('has_utm');

    if (utmParams) {
      saveAnonymousProfile({
        utm: utmParams,
      });
      cookies.set('has_utm', 'true');
      this.$auth.$storage.setUniversal(
        'redirect',
        `/?${new URLSearchParams(utmParams).toString()}`
      );
    } else if (!hasUtmCookie) {
      saveAnonymousProfile({ utm: {} });
    }

    this.user_agent = navigator && navigator.userAgent;
    this.device_type = deviceType;

    this.setNavigationVariant('none');
  },
  methods: {
    ...mapActions('ruach', ['setNavigationVariant']),
  },
};
