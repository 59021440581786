
import { mapActions } from 'vuex';
import GET_INVITES_QUERY from '@/graphql/queries/GetInvites';
import SEND_INVITE_REMINDER from '@/graphql/mutations/SendInviteReminder';
import ARCHIVE_INVITE_MUTATION from '@/graphql/mutations/ArchiveInvite';
import UPDATE_INVITE_MUTATION from '@/graphql/mutations/UpdateInvite';
import confirm from '@/mixins/confirm';
import { will, user } from '@/mixins/apollo';
import { formatError, is24HoursFromNow } from '@/utilities';

export default {
  name: 'AppAccountSentInvite',
  mixins: [will, user, confirm],
  props: {
    invite: {
      type: Object,
      required: true,
    },
    productsToShow: {
      type: Array,
      default: () => [],
    },
    resendable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    cancelable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      error: '',
      success: '',
      loading: false,
      done: false,
      showAlert: null,
      alertTheme: null,
      alertMessage: null,
      editEmailModalIsVisible: false,
      cancelGiftModalIsVisible: false,
      newEmail: '',
      isInvalid: null,
    };
  },
  computed: {
    recentlyInvited() {
      return is24HoursFromNow(this.invite.remindedAt);
    },
    sentProducts() {
      return this.productsToShow.filter((product) => {
        return this.invite.products.includes(product);
      });
    },
    variables() {
      return {
        type: this.type,
        email: this.email,
      };
    },
    getInvitesQuery() {
      return {
        query: GET_INVITES_QUERY,
        variables: {
          userId: this.userId,
        },
        skip() {
          return !this.userId;
        },
      };
    },
    productsLabel() {
      const suffix = this.sentProducts.length > 1 ? 's' : '';
      return `${this.$t('components.sentInvite.product')}${suffix}:`;
    },
  },
  methods: {
    ...mapActions('invites', ['getInvites']),
    async sendReminder(id) {
      try {
        this.loading = true;
        const { data } = await this.$apollo.mutate({
          mutation: SEND_INVITE_REMINDER,
          variables: { id },
          update: () => {
            this.getInvites();
          },
        });
        this.alertTheme = 'success';
        this.alertMessage = data.sendInviteReminder.message;
      } catch (error) {
        this.alertTheme = 'error';
        this.alertMessage = formatError(error.message);
      }
      this.loading = false;
    },
    openCancelGiftModal() {
      this.cancelGiftModalIsVisible = true;
    },
    closeCancelGiftModal() {
      this.cancelGiftModalIsVisible = false;
    },
    openEditEmailModal() {
      this.editEmailModalIsVisible = true;
    },
    closeEditEmailModal() {
      this.editEmailModalIsVisible = false;
    },
    async updateInviteEmail() {
      this.closeEditEmailModal();

      this.error = null;
      this.success = null;
      this.loading = true;

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_INVITE_MUTATION,
          variables: {
            id: this.invite.id,
            email: this.newEmail,
            products: this.invite.products,
          },
          update: (store, { data: { updateInvite } }) => {
            if (updateInvite) {
              this.getInvites();
            }
          },
        });
        this.success = this.$t('components.sentInvite.emailUpdated');
      } catch (error) {
        this.error = formatError(error.message);
      }
      this.newEmail = null;
      this.loading = false;
    },
    async archiveInvite() {
      this.error = null;
      this.alertMessage = null;
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: ARCHIVE_INVITE_MUTATION,
          variables: {
            id: this.invite.id,
          },
          update: (store, { data: { archiveInvite } }) => {
            if (archiveInvite) {
              this.getInvites();
            }
          },
        });
      } catch (error) {
        this.error = formatError(error.message);
      }
      this.loading = false;
      this.closeCancelGiftModal();
    },
  },
};
