import { render, staticRenderFns } from "./ModuleSummary.vue?vue&type=template&id=672227e8"
import script from "./ModuleSummary.vue?vue&type=script&lang=js"
export * from "./ModuleSummary.vue?vue&type=script&lang=js"
import style0 from "./ModuleSummary.vue?vue&type=style&index=0&id=672227e8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppFlagsDropdown: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/snowflakes/FlagsDropdown.vue').default,AppPlanFlagsListing: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/snowflakes/PlanFlagsListing.vue').default})
