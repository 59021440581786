
export default {
  name: 'AppTooltip',
  props: {
    content: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      default: null,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    placement: {
      default: null,
      type: String,
    },
    maxWidthClass: {
      default: 'max-w-md',
      type: String,
    },
    background: {
      default: 'bg-black',
      type: String,
    },
  },
  computed: {
    options() {
      const options = {
        classes: [
          this.maxWidthClass,
          this.background,
          'px-4',
          'py-3',
          'mb-4',
          'rounded',
          'shadow',
          'font-medium',
          'text-center',
          'text-white',
        ],
        content: () => {
          if (!this.header) {
            return this.content;
          }
          return `<div><h2 class="app:tooltip__header">${this.header}</h2><p class="app:tooltip__content">${this.content}</p></div>`;
        },
        hideOnTargetClick: false,
        container: false,
        popperOptions: this.popperOptions,
      };
      if (this.placement) {
        options.placement = this.placement;
      }
      return options;
    },
    popperOptions() {
      return {
        modifiers: {
          computeStyle: {
            gpuAcceleration: false,
          },
        },
      };
    },
  },
};
