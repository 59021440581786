
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppWillSubmitHelperModal',
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    ...mapGetters('ui', ['showSubmitHelperModal']),
    variables() {
      return {
        id: this.willId,
      };
    },
  },
  methods: {
    ...mapActions('ui', ['setShowSubmitHelperModal']),
    close() {
      this.setShowSubmitHelperModal(false);
    },
  },
};
