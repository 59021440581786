
import { mapGetters, mapActions } from 'vuex';
import { charityCategories } from '@/modules/charityData';
import ADD_PARTNERSHIP_MUTATION from '@/graphql/mutations/AddPartnership.gql';

export default {
  name: 'AppNewPartnershipModal',
  emits: ['partnershipAdded'],
  data: () => ({
    ADD_PARTNERSHIP_MUTATION,
    availableCharityCategories: charityCategories,
    formData: {
      name: null,
      abn: null,
      address: null,
      categories: [],
    },
  }),
  computed: {
    ...mapGetters('admin/partnership', ['showNewPartnershipModal']),
    variables() {
      return {
        partnership: {
          ...this.formData,
          abn: '',
          address: '',
          logo: '',
          landingPages: [],
          altNames: [],
          utmPrefixes: [],
          featuredFive: false,
          dashboardEnabled: false,
          bequestEnabled: false,
          affiliateEnabled: false,
          active: false,
          archived: false,
          region: null,
          relatedPartnershipIds: [],
          contacts: [],
          appHeaderAffiliateLogoShown: false,
          getStartedAffiliateLogoShown: false,
        },
      };
    },
  },
  methods: {
    ...mapActions('admin/partnership', ['setShowNewPartnershipModal']),
    close() {
      this.setShowNewPartnershipModal(false);
    },
    async update(_store, res) {
      const createdPartnership = res.data.createPartnership;
      this.$emit('partnershipAdded');
      await this.$router.push(
        this.localePath(`/admin/partnerships/${createdPartnership.id}`)
      );
    },
  },
};
