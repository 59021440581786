
import { mapActions, mapGetters } from 'vuex';
import { will } from '@/mixins/apollo';

export default {
  name: 'AppWillUnlockWillModal',
  mixins: [will],
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters('ui', ['showUnlockWillModal']),
  },
  methods: {
    ...mapActions('ui', ['setShowUnlockWillModal']),
    ...mapActions('will', { versionWillAction: 'versionWill' }),
    ...mapActions('flag', ['getPlanFlags']),
    setLoading(value) {
      this.loading = value;
    },
    close() {
      this.setShowUnlockWillModal(false);
    },
    async versionWill() {
      if (this.loading) return;

      this.setLoading(true);
      const newWill = await this.versionWillAction();
      await this.getPlanFlags({ planId: newWill.id });
      this.setLoading(false);
      this.close();
    },
  },
};
