import { render, staticRenderFns } from "./EstateCharityPanel.vue?vue&type=template&id=e5ef28ec"
import script from "./EstateCharityPanel.vue?vue&type=script&lang=js"
export * from "./EstateCharityPanel.vue?vue&type=script&lang=js"
import style0 from "./EstateCharityPanel.vue?vue&type=style&index=0&id=e5ef28ec&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppWillCauseSelector: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/will/CauseSelector.vue').default,AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,AppFormControl: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/FormControl.vue').default,AppCharity: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Charity.vue').default,WpVerticalSelectButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/VerticalSelectButton.vue').default,WpButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/Button.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppTip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Tip.vue').default,AppForm: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Form.vue').default,AppPanel: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Panel.vue').default})
