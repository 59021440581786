
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { formatPrice } from '@/utilities';
import {
  WILL_BASE_PRICE,
  COUNTRY,
  ONBOARDING_STEP,
} from '@/utilities/constants';
import GET_PARTNERSHIP_APP_DATA from '@/graphql/queries/GetPartnershipAppData';

export default {
  name: 'PagesAffiliateOnboardingUserFinish',
  layout: 'ruach',
  computed: {
    ...mapGetters('user-onboarding', ['loading']),
    ...mapFields('user-onboarding', ['payload.firstName', 'payload.country']),
    ...mapGetters('affiliate', ['slug']),
    ...mapGetters('banners', ['showOver60Banner']),
    ...mapGetters(['willId', 'userId']),
    ...mapGetters('product-prices', ['willPrice']),
    headlineWithWillPrice() {
      const name = this.firstName;
      const price = formatPrice(this.willPrice);

      // TODO get the base price from the API https://safewill.atlassian.net/browse/DEV-5802
      if (this.willPrice < WILL_BASE_PRICE) {
        return this.$t('pages.onboarding.newUser.page4.headlineDiscounted', {
          name,
          basePrice: formatPrice(WILL_BASE_PRICE),
          price,
        });
      }

      return this.$t('pages.onboarding.newUser.page4.headlineBase', {
        name,
        price,
      });
    },
  },
  mounted() {
    this.getProductPrices();
    this.initOnboarding(ONBOARDING_STEP.FINISH);
  },
  methods: {
    ...mapActions(['setCharitySource', 'setWillStatus']),
    ...mapActions('user-onboarding', ['initOnboarding']),
    ...mapActions('cart', ['addToCart']),
    ...mapActions('product-prices', ['getProductPrices']),
    ...mapActions('will', ['updateWillStatus']),
    async nextStep(bypassDashboard = false) {
      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'sign_up',
        props: {
          userId: this.userId,
        },
      });

      await this.updatePartnershipAppData();

      let next = '';
      if (this.country === COUNTRY.NZ) {
        next += '/nz';
      }
      if (this.slug) {
        next += `/${this.slug}`;
      }
      if (bypassDashboard) {
        await Promise.all([
          this.addToCart({ codes: ['WILL'], showSnackbar: false }),
          this.updateWillStatus('IN_PROGRESS'),
        ]);
        next += '/will/about-yourself/name';
      }
      this.$router.push({
        path: next,
      });
    },
    async updatePartnershipAppData() {
      const { data: partnershipAppData } = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: GET_PARTNERSHIP_APP_DATA,
      });
      await this.setCharitySource(partnershipAppData.getPartnershipAppData);
    },
  },
};
