import { render, staticRenderFns } from "./NewPartnershipModal.vue?vue&type=template&id=45876b30"
import script from "./NewPartnershipModal.vue?vue&type=script&lang=js"
export * from "./NewPartnershipModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppFormControl: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/FormControl.vue').default,AppTextInput: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/TextInput.vue').default,AppFormRow: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/deprecated/FormRow.vue').default,AppForm: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Form.vue').default,AppModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Modal.vue').default})
