
import CREATE_BULK_COUPON_MUTATION from '@/graphql/mutations/CreateBulkCoupon.gql';

export default {
  name: 'AppBulkCreateCouponModal',
  data: () => ({
    CREATE_BULK_COUPON_MUTATION,
    loading: false,
    checkboxProductsMap: {
      WILL: false,
      POA: false,
      PARTNER_WILL: false,
    },
    formData: {
      partnershipSlug: null,
      description: null,
      type: 'PERCENTAGE',
      countryCode: null,
      singleUse: false,
      factor: 0,
      products: [],
      quantity: 1,
    },
  }),
  computed: {
    variables() {
      return {
        ...this.formData,
        quantity: parseInt(this.formData.quantity, 10),
        factor: parseInt(this.formData.factor),
      };
    },
  },
  methods: {
    close() {
      this.$emit('closeModal');
    },
    update(store, res) {
      this.$emit(
        res.data?.createBulkCoupon?.success ? 'addedCoupon' : 'failedCoupon'
      );
    },
    handleChangeCheckboxProducts(key, value) {
      this.checkboxProductsMap[key] = value;
      this.formData.products = Object.entries(this.checkboxProductsMap)
        .filter((el) => el[1])
        .map((el) => el[0]);
    },
  },
};
