
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';

import institutionsAdmin from '@/mixins/institutions-admin';
import sorting from '@/mixins/sorting';

export default {
  name: 'PagesAffiliateAdminInstitutionsIndex',
  mixins: [institutionsAdmin, sorting],
  data() {
    return {
      newInstitutionModalIsVisible: false,
      columns: ['Name', 'Type', 'Created At', 'Updated At'],
      searchQuery: '',
      sortableFields: {
        name: {
          field: 'name',
          columnName: 'Name',
          defaultDirection: 'ASC',
        },
        type: {
          field: 'type',
          columnName: 'Type',
          defaultDirection: 'ASC',
        },
        createdAt: {
          field: 'createdAt',
          columnName: 'Created At',
          defaultDirection: 'DESC',
        },
        updatedAt: {
          field: 'updatedAt',
          columnName: 'Updated At',
          defaultDirection: 'DESC',
        },
      },
      currentSortingColumn: ['Created At', 'DESC'],
    };
  },
  computed: {
    ...mapGetters('admin', ['institutionsPageIndex']),
  },
  methods: {
    ...mapActions('admin', [
      'nextInstitutionsPage',
      'prevInstitutionsPage',
      'resetInstitutionsPageIndex',
      'setInstitutionsSearchQuery',
    ]),
    openNewInstitutionModal() {
      this.newInstitutionModalIsVisible = true;
    },
    closeNewInstitutionModal() {
      this.newInstitutionModalIsVisible = false;
    },
    async reloadInstitutions(createdInstitution) {
      await this.refetchInstitutions();
      await this.$router.push(
        this.localePath(`/admin/institutions/${createdInstitution.id}`)
      );
    },
    nextPage() {
      if (!this.$apollo.loading) {
        this.nextUsersPage();
      }
    },
    prevPage() {
      if (!this.$apollo.loading) {
        this.prevUsersPage();
      }
    },
    search: debounce(300, function (event) {
      this.resetInstitutionsPageIndex();
      this.setInstitutionsSearchQuery(event.target.value.trim());
    }),
    changeSortColumnAndResetPageIndex(columnName) {
      this.changeSortColumn(columnName);
      this.resetInstitutionsPageIndex();
    },
  },
};
