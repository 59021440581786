
import { mapGetters } from 'vuex';
import { executors, user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillExecutorsOptions',
  mixins: [executors, user, will],
  middleware({ store, redirect }) {
    if (!store.getters['directory-person/userIsAustralian']) {
      return redirect('/will/executors');
    }
  },
  data() {
    return {
      videoPlayerState: {
        currentTime: 0,
        duration: 0,
        currentTimeString: 0,
        durationString: 0,
        played: false,
        expanded: false,
      },
      oldExecutorOption: undefined,
      executorTodoListIsVisible: false,
      executorComparisonPanelIsVisible: false,
      suggestedOptionIsVisible: false,
      executorFeesPanelIsVisible: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('directory-person', ['contacts']),
    ...mapGetters('product', ['executorProducts']),
    isDefaultExecutorOptionSelected() {
      return (
        !!this.willMeta.executors_option &&
        [
          'professional',
          'friendsFamilyAndProfessional',
          'friendsFamily',
        ].includes(this.willMeta.executors_option)
      );
    },
    videoUrl() {
      return 'https://storage.googleapis.com/docs-safewill-operation-australia-southeast1/Executor-Video.mp4#t=0.1';
    },
    videoSubtitlesUrl() {
      return 'https://storage.googleapis.com/docs-safewill-operation-australia-southeast1/Executor-Video-subtitles.vtt';
    },
    executorProductsOptions() {
      return this.$ff.ptgActExecutorOption()
        ? this.executorProducts.map((product) => ({
            label: this.$t(
              `pages.will.executors.options.${product.code}.label`
            ),
            description: this.$t(
              `pages.will.executors.options.${product.code}.description`
            ),
            value: product.code,
          }))
        : [];
    },
    executorOptions() {
      return [
        ...this.executorProductsOptions,
        {
          label: this.$t('pages.will.executors.options.professional.label'),
          description: this.$t(
            'pages.will.executors.options.professional.description'
          ),
          value: 'professional',
        },
        {
          label: this.$t(
            'pages.will.executors.options.friendsFamilyAndProfessional.label'
          ),
          description: this.$t(
            'pages.will.executors.options.friendsFamilyAndProfessional.description'
          ),
          value: 'friendsFamilyAndProfessional',
        },
        {
          label: this.$t('pages.will.executors.options.friendsFamily.label'),
          description: this.$t(
            'pages.will.executors.options.friendsFamily.description'
          ),
          value: 'friendsFamily',
        },
      ];
    },
  },
  watch: {
    'willMeta.executors_option': function (newValue) {
      if (newValue === null && this.executorProductsOptions.length > 0) {
        this.willMeta.executors_option = this.executorProductsOptions[0].value;
      }

      if (this.oldExecutorOption == null) {
        this.oldExecutorOption = newValue;
      }
    },
  },
  mounted() {
    this.oldExecutorOption = this.willMeta.executors_option;
  },
  methods: {
    onVideoCompletion() {
      this.$nuxt.$emit('sendTrackingAttributes', {
        watchedExecutorVideo: 'True',
      });
    },
    toggleExecutorToDoList() {
      this.executorTodoListIsVisible = !this.executorTodoListIsVisible;
    },
    toggleExecutorFeesPanel() {
      this.executorFeesPanelIsVisible = !this.executorFeesPanelIsVisible;
    },
    async onSubmit() {
      this.isLoading = true;

      let path;
      if (
        ['friendsFamilyAndProfessional', 'professional'].includes(
          this.willMeta.executors_option
        ) ||
        !this.willMeta.professional_executor_terms_agreed_at
      ) {
        this.willMeta.professional_executor_terms_agreed_at = null;
      }

      switch (this.willMeta.executors_option) {
        case 'friendsFamily':
          path = '/will/executors/primary';
          break;
        case 'professional':
          path = '/will/executors/professional-terms';
          this.willMeta.has_backup_executor = false;
          if (this.oldExecutorOption !== 'professional') {
            await Promise.all(
              this.contacts.map((contact) => {
                if (
                  this.isPrimaryExecutor(contact) ||
                  this.isBackupExecutor(contact)
                ) {
                  return this.removeExecutor(contact);
                }
                return Promise.resolve();
              })
            );
          }

          break;
        case 'friendsFamilyAndProfessional':
          path = '/will/executors/primary-contact';
          this.willMeta.has_backup_executor = false;

          await Promise.all(
            this.contacts.map((contact) => {
              if (this.isBackupExecutor(contact)) {
                return this.removeExecutor(contact);
              }
              return Promise.resolve();
            })
          );

          break;
        default:
          path = '/will/executors/backup';
          this.willMeta.has_backup_executor = true;

          await Promise.all(
            this.contacts.map((contact) => {
              if (this.isPrimaryExecutor(contact)) {
                return this.removeExecutor(contact);
              }
              return Promise.resolve();
            })
          );
          break;
      }

      await this.updateWillMeta();

      this.$router.push({ path: this.localePath(path) });
    },
  },
};
