
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppGlossaryPanel',
  computed: {
    ...mapGetters('glossary', ['showGlossaryPanel']),
    sortedTerms() {
      return Object.keys(this.$t('glossary')).sort((a, b) => a - b);
    },
  },
  methods: {
    ...mapActions('glossary', ['setShowGlossaryPanel']),
    close() {
      this.setShowGlossaryPanel(false);
    },
  },
};
