import { render, staticRenderFns } from "./Tabs.vue?vue&type=template&id=419e1cb4"
import script from "./Tabs.vue?vue&type=script&lang=js"
export * from "./Tabs.vue?vue&type=script&lang=js"
import style0 from "./Tabs.vue?vue&type=style&index=0&id=419e1cb4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,AppTabTitle: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/TabTitle.vue').default,AppTabContent: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/TabContent.vue').default})
