
import institutions from '@/mixins/institutions';
import {
  GOVERNMENT_CONCESSION_OPTIONS,
  RELATIONSHIP_OPTIONS,
  POA_OPTIONS,
  BANK_ACCOUNT_OPTIONS,
  PROPERTY_OPTIONS,
  PROPERTY_OWNERSHIP_OPTIONS,
  BUSINESS_OPTIONS,
  FINANCIAL_ASSET_OPTIONS,
  INSURANCE_OPTIONS,
  LOAN_OPTIONS,
  UTILITY_OPTIONS,
  FREQUENCY_OPTIONS,
  GOVERNMENT_BENEFIT_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  FUNERAL_TYPE_OPTIONS,
  FUNERAL_DIRECTOR_OPTIONS,
  FUNERAL_PREPAID_TYPE_OPTIONS,
  EMOTIONAL_MESSAGE_MODE_OF_DELIVERY_OPTIONS,
  ROLE_IN_TRUST_OPTIONS,
} from '@/utilities/end-of-life/form-constants';

export default {
  name: 'AppEndOfLifeFieldset',
  mixins: [institutions],
  props: {
    layout: {
      type: String,
      default: 'DEFAULT',
    },
    institutionType: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      required: true,
    },
    question: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentInstitutionType: null,
      institution: this.value.data.otherInstitutionName
        ? 'OTHER'
        : this.value.data.institutionId,
      institutionOptions: [],
      formData: this.value,
      GOVERNMENT_CONCESSION_OPTIONS,
      RELATIONSHIP_OPTIONS,
      POA_OPTIONS,
      BANK_ACCOUNT_OPTIONS,
      PROPERTY_OPTIONS,
      PROPERTY_OWNERSHIP_OPTIONS,
      BUSINESS_OPTIONS,
      FINANCIAL_ASSET_OPTIONS,
      INSURANCE_OPTIONS,
      LOAN_OPTIONS,
      UTILITY_OPTIONS,
      FREQUENCY_OPTIONS,
      GOVERNMENT_BENEFIT_OPTIONS,
      EMPLOYMENT_TYPE_OPTIONS,
      FUNERAL_TYPE_OPTIONS,
      FUNERAL_DIRECTOR_OPTIONS,
      FUNERAL_PREPAID_TYPE_OPTIONS,
      EMOTIONAL_MESSAGE_MODE_OF_DELIVERY_OPTIONS,
      ROLE_IN_TRUST_OPTIONS,
    };
  },
  computed: {
    isQuestionLayout() {
      return ['FUNERAL_PREFERENCE', 'FUNERAL_PREPAID_POLICY'].includes(
        this.layout
      );
    },
  },
  watch: {
    currentInstitutionType() {
      this.getAndSetInstitutionOptions();
    },
    institution(value) {
      if (value === 'OTHER') {
        this.formData.data = {
          ...this.formData.data,
          institutionId: null,
        };
      } else {
        this.formData.data = {
          ...this.formData.data,
          institutionId: value,
          otherInstitutionName: null,
        };
      }
    },
    formData: {
      handler(newData) {
        this.$emit('input', newData);
      },
      deep: true,
    },
    value: {
      handler(newData) {
        this.formData = newData;
      },
      deep: true,
    },
  },
  mounted() {
    this.updateCurrentInstitution();
  },
  created() {
    if (this.isQuestionLayout) {
      this.formData.data.name = this.question.name;
      this.formData.data.key = this.question.key;
    }
  },
  methods: {
    updateCurrentInstitution() {
      if (this.isFieldsetVisible('UTILITY')) {
        this.currentInstitutionType = this.getCurrentInstitutionForSubscription(
          this.formData.data.type
        );
      } else {
        this.currentInstitutionType = this.institutionType;
      }
    },
    getCurrentInstitutionForSubscription(current) {
      switch (current) {
        case 'Mobile':
          return 'Mobile';
        case 'Internet':
          return 'Internet';
        default:
          return 'Utilities';
      }
    },
    getCurrentInstitutionForLoan(current) {
      if (current === 'Mortgage') {
        return 'Mortgage';
      }
      return 'Personal Loan';
    },
    async getAndSetInstitutionOptions() {
      const institutions = await this.getInstitutionsByType(
        this.currentInstitutionType
      );

      this.institutionOptions = institutions.map(({ id, name }) => ({
        text: name,
        value: id,
      }));
    },
    isFieldsetVisible(fieldset) {
      return fieldset === this.layout;
    },
  },
};
