
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import RESEND_WELCOME_EMAIL from '@/graphql/mutations/ResendWelcomeEmail';
import UPDATE_EMAIL_MUTATION from '@/graphql/mutations/UpdateEmail';
import UPDATE_WILL_NOTIFY_CHARITY_MUTATION from '@/graphql/mutations/UpdateNotifyCharityOfWill';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateDashboardAccountSettings',
  mixins: [user, will],
  data() {
    return {
      UPDATE_WILL_NOTIFY_CHARITY_MUTATION,
      UPDATE_EMAIL_MUTATION,
      confirmPassword: '',
      email: this.$store.state.email,
      resendEmailText: 'Resend verification email',
      resendLoading: false,
      resendTimeout: null,
      validEmail: false,
      validPassword: false,
      welcomeEmailSent: false,
      hasChangedPassword: false,
    };
  },
  computed: {
    ...mapGetters('directory-person', ['userDetails']),
    ...mapFields('directory-person', [
      'user.firstName',
      'user.lastName',
      'user.middleName',
      'user.phoneNumbers',
      'user.residentialAddress.streetAddress',
      'user.residentialAddress.locality',
      'user.residentialAddress.region',
      'user.residentialAddress.postcode',
      'user.residentialAddress.country',
    ]),
    ...mapGetters(['verified']),
    ...mapGetters('ui', ['showConfirmPasswordModal']),
    defaultPhoneNumber: {
      get() {
        return this.phoneNumbers.find(
          (phoneNumber) => phoneNumber.type === 'DEFAULT'
        )?.value;
      },
      set(value) {
        this.phoneNumbers = [
          ...this.phoneNumbers.filter(
            (phoneNumber) => phoneNumber.type !== 'DEFAULT'
          ),
          ...(value
            ? [
                {
                  type: 'DEFAULT',
                  value,
                },
              ]
            : []),
        ];
      },
    },
    showNotifyCharities() {
      return this.willMeta.notify_charities !== null;
    },
    showExecutorTermsAndConditions() {
      return Boolean(
        ['professional', 'friendsFamilyAndProfessional'].includes(
          this.willMeta.executors_option
        ) && this.willMeta.professional_executor_terms_agreed_at
      );
    },
    updateEmailVariables() {
      return {
        currentPassword: this.confirmPassword,
        newEmail: this.email,
      };
    },
    UPDATE_WILL_NOTIFY_CHARITY_VARS() {
      return {
        userId: this.userId,
        value: this.willMeta.notify_charities,
      };
    },
  },
  methods: {
    ...mapActions(['setEmail', 'setToken']),
    ...mapActions('ui', ['setShowConfirmPasswordModal']),
    ...mapActions('directory-person', [
      'updateDirectoryPerson',
      'updatePartnershipConsent',
    ]),
    closeModal() {
      this.setShowConfirmPasswordModal(false);
      this.confirmPassword = '';
      this.$nuxt.$emit('unlockForm');
    },
    async resendWelcomeEmail() {
      this.resendLoading = true;

      await this.$apollo
        .mutate({
          mutation: RESEND_WELCOME_EMAIL,
          variables: {
            email: this.email,
          },
        })
        .then(({ data }) => {
          this.resendLoading = false;

          if (data.resendWelcomeEmail?.success) {
            this.welcomeEmailSent = true;

            this.resendTimeout = window.setTimeout(() => {
              this.resendTimeout = null;
            }, 3000);
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    },
    async updateWills() {
      await this.updatePartnershipConsent(this.willMeta.notify_charities);
      await this.refetchWill();
      this.$nuxt.$emit('unlockForm');
      this.$nuxt.$emit('clearErrors');
    },
    async updateUserDetails() {
      await this.updateDirectoryPerson({
        id: this.userDetails.id,
        firstName: this.firstName,
        lastName: this.lastName,
        middleName: this.middleName,
        phoneNumbers: this.phoneNumbers,
        residentialAddress: {
          id: this.userDetails.residentialAddress.id,
          streetAddress: this.streetAddress,
          locality: this.locality,
          region: this.region,
          postcode: this.postcode,
          country: this.country,
        },
      });
      this.$nuxt.$emit('sendTrackingAttributes', {
        name: this.userDetails.fullName,
      });

      this.$ff.refreshFeatureFlags();

      this.$nuxt.$emit('unlockForm');
    },
    async updateToken({
      data: {
        updateEmail: { token, user },
      },
    }) {
      await this.$apolloHelpers
        .onLogin(token)
        .then(() => {
          this.setEmail(user.email);
          this.setToken(token);
          this.$ff.refreshFeatureFlags();
        })
        .catch((e) => {
          console.error(e.message);
        });

      this.closeModal();
    },
  },
};
