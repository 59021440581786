
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { user } from '@/mixins/apollo';
import ADD_COUPON_MUTATION from '@/graphql/mutations/AddCoupon';

const { mapFields } = createHelpers({
  getterType: 'getCouponField',
  mutationType: 'updateCouponField',
});

export default {
  name: 'AppCouponModal',
  mixins: [user],
  data: () => ({
    loading: false,
    checkboxProductsMap: {
      WILL: false,
      POA: false,
      PARTNER_WILL: false,
    },
    ADD_COUPON_MUTATION,
  }),
  computed: {
    ...mapFields('admin/coupon', [
      'fields.code',
      'fields.type',
      'fields.description',
      'fields.used',
      'fields.factor',
      'fields.active',
      'fields.products',
    ]),
    ...mapGetters('admin/coupon', ['fields']),
    variables() {
      return { ...this.fields };
    },
  },
  methods: {
    close() {
      this.$emit('closeModal');
    },
    update() {
      this.$emit('addedCoupon');
    },
    handleChangeCheckboxProducts(key, value) {
      this.checkboxProductsMap[key] = value;
      this.products = Object.entries(this.checkboxProductsMap)
        .filter((el) => el[1])
        .map((el) => el[0]);
    },
  },
};
