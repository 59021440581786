
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';

import GET_USERS_QUERY from '@/graphql/queries/GetUsers';

import sorting from '@/mixins/sorting';
import { isPartnershipsAdminOrHigher } from '@/utilities';

export default {
  name: 'PagesAffiliateAdminUsersIndex',
  mixins: [sorting],
  apollo: {
    users: {
      query: GET_USERS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          limit: this.pageSize,
          offset: this.usersPageIndex * this.pageSize,
          searchQuery: this.usersPageSearch ?? undefined,
          customOrder: this.customOrder,
          ...(this.userRoleFilter && { roles: [this.userRoleFilter] }),
        };
      },
      update: (data) => data.getUsers,
    },
  },
  data() {
    return {
      searchQuery: '',
      columns: ['ID', 'Email', 'Role', 'Created At'],
      pageSize: 10,
      filters: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Super Admin',
          value: 'SUPER_ADMIN',
        },
        {
          label: 'Partnerships Admin',
          value: 'PARTNERSHIPS_ADMIN',
        },
        {
          label: 'Admin',
          value: 'ADMIN',
        },
        {
          label: 'Moderator',
          value: 'MODERATOR',
        },
        {
          label: 'Partnership',
          value: 'PARTNERSHIP',
        },
        {
          label: 'Consumer',
          value: 'CONSUMER',
        },
      ],
      users: [],
      showAddUserButton: false,
      showAddUserModal: false,
      sortableFields: [
        {
          field: 'id',
          columnName: 'ID',
          defaultDirection: 'ASC',
        },
        {
          field: 'email',
          columnName: 'Email',
          defaultDirection: 'ASC',
        },
        {
          field: 'role',
          columnName: 'Role',
          defaultDirection: 'ASC',
        },
        {
          field: 'createdAt',
          columnName: 'Created At',
          defaultDirection: 'DESC',
        },
      ],
      currentSortingColumn: ['Created At', 'DESC'],
    };
  },
  computed: {
    ...mapGetters(['role']),
    ...mapGetters('admin', [
      'usersPageIndex',
      'usersPageSearch',
      'userRoleFilter',
    ]),
    searchValue() {
      return this.usersPageSearch;
    },
  },
  watch: {
    userRoleFilter() {
      this.resetUsersPageIndex();
    },
  },
  created() {
    this.showAddUserButton = isPartnershipsAdminOrHigher(this.role);
  },
  methods: {
    ...mapActions('admin', [
      'nextUsersPage',
      'prevUsersPage',
      'resetUsersPageIndex',
      'setUsersPageSearch',
      'setUserRoleFilter',
    ]),
    nextPage() {
      if (!this.$apollo.loading) {
        this.nextUsersPage();
      }
    },
    prevPage() {
      if (!this.$apollo.loading) {
        this.prevUsersPage();
      }
    },
    search: debounce(300, function (event) {
      this.resetUsersPageIndex();
      this.setUsersPageSearch(event.target.value.trim());
    }),
    setShowAddUserModal(show) {
      this.showAddUserModal = show;
    },
    showUserPage(userId) {
      this.$router.push({
        path: `/admin/users/${userId}`,
      });
    },
  },
};
